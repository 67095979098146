<template>
    <div class="c-section c-modules" :class=" ('is-loading' && modificator!=='Libraries')?loading:''">
        <div v-if=" modificator !== 'Demo'" class="c-section-heading">
            <p class="c-section-title">Modules</p>
        </div>

      <div
        @mousedown.left="selectListener"
        @mousedown.right.prevent="deleteListener"
        @contextmenu.prevent

      >
        <drag-select attribute="customAttribute" @change="dselected = $event" class="c-section-list">
            <li
              v-for="(module, index) in value.filter(v=> v.displayed !== false).filter(val => val)"
                :key="'module' + index"
                :title="modificator=='Libraries'  || modificator=='Upload'?module.fileName+(module.error?' ('+module.error+')':''):''"
                :customAttribute="'module' + index"
                class="c-section-list__item"
                @click.prevent="singeSelect(index)"
                @contextmenu="remove(index)"
                :class="{'item-selected-class': dselected.includes('module' + index)}"
            >
                <div class="c-modules-item" style="pointer-events: none">
                    <a
                        :ref="'module' + index"
                        href=""
                        class="c-modules-item__link"
                        :class="{ 'is-active': module.selected }"
                    >
                        <img :src="module.dataUri" alt="">
                    </a>
                </div>
            </li>
      </drag-select>
        <li
          class="c-section-list__item"
          v-if="modificator!=='Libraries' && modificator !== 'Front' && modificator !== 'Demo'"
        >
          <div class="c-section-add" >

            <input
              id="module_upload"
              ref="fileInput"
              class="c-section-add__input"
              type="file"
              accept=".svg"
              multiple
              @change="change"
            >
            <label
              v-if="modificator!=='Libraries' && modificator !== 'Front' && modificator !== 'Demo'"

              for="module_upload" class="c-section-add__link">
                        <span class="c-section-add__link-inner">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="12.5" y1="2.18557e-08" x2="12.5" y2="24" stroke="#444444"/>
<line x1="24" y1="12.5" x2="-4.37114e-08" y2="12.5" stroke="#444444"/>
</svg>
                        </span>
            </label>
          </div>
        </li>
      </div>
    </div>
</template>

<script>

import { parse } from 'postsvg'
import render from 'posthtml-render'
import DragSelect from 'drag-select-vue'
import { getAverageColor } from 'fast-average-color-node'

export default {
  components: {
    DragSelect
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    currentKey: {
      type: [Number, String],
      required: false,
      default: null
    },
    modificator: {
      type: String,
      required: false,
      default: ''
    },
    modulError: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  data () {
    return {
      modules: [],
      uploadingFiles: [],
      loading: true,
      dselected: [],
      knock: []
    }
  },
  computed: {
    loadedModules () {
      return this.modules.filter(module => module.svgString)
    }
  },
  watch: {
    value () {
      this.modules = this.value
    },
    uploadingFiles (value) {
      if (value.length) {
        this.loading = true
      } else {
        this.$refs.fileInput.value = ''
        this.loading = false
      }
    },
    loadedModules () {
      if (this.loadedModules.length === this.modules.length) {
        this.$emit('loaded')
        this.loading = false
      }
    }
  },
  mounted () {
    this.modules = this.value
  },
  methods: {
    selectListener () {
      window.addEventListener('mouseup', this.applySelected, false)
    },
    deleteListener (e) {
      if (this.modificator !== 'Libraries') {
        window.addEventListener('mouseup', this.deleteSelected, false)
      } else {
        e.preventDefault()
      }
    },
    deleteSelected (e) {
      if (this.modificator !== 'Demo') {
        e.preventDefault()
        if (this.modificator !== 'Libraries') {
          if (this.currentKey === 'Ctrl') {
            this.modules = []
            this.$emit('input', this.modules)
          } else {
            if (this.dselected.length > 1) {
              const indexed = []
              this.dselected.forEach(index => {
                indexed.push(Number(index.slice(6)))
              })
              this.modules = this.modules.filter(function (value, index, arr) {
                return !indexed.includes(index)
              })
              this.$emit('input', this.modules)
              this.dselected = []
            } else {
              this.dselected = []
            }
          }
        }
        window.removeEventListener('mouseup', this.deleteSelected, false)
        this.$emit('input', this.modules)
      } else {
        window.removeEventListener('mouseup', this.deleteSelected, false)
        this.dselected = []
      }
    },
    singeSelect (index) {
      if (this.currentKey === 'Alt') {
        this.$emit('altleft', this.modules[index].indexer)
      } else {
        this.modules[index].selected = !this.modules[index].selected
        this.$emit('input', this.modules)
        this.$emit('manualchange')
      }
    },
    applySelected () {
      if (this.dselected.length > 0) {
        if (this.dselected.length === 1) {
          this.dselected = []
        } else {
          this.dselected.forEach(index => {
            index = index.slice(6)
            if (this.currentKey === 'Ctrl') {
              this.modules[index].selected = false
            } else if (this.currentKey === 'Shift') {
              this.modules[index].selected = true
            } else {
              this.modules[index].selected = !this.modules[index].selected
            }
          })
        }
        this.dselected = []
        this.$emit('input', this.modules)
      }
      window.removeEventListener('mouseup', this.applySelected, false)
      this.$emit('manualchange')
    },
    select (index) {
      if (this.currentKey === 'Ctrl') {
        this.modules.forEach(module => {
          module.selected = false
        })
      } else {
        this.modules[index].selected = !this.modules[index].selected
      }

      this.$emit('input', this.modules)
    },
    uploadFile (file) {
      const reader = new FileReader()
      const fileName = file.name
      const tags = (file.name.replace(/\d/g, '').split('.')[0].split('-'))
      reader.readAsText(file)
      reader.onerror = () => {
        this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1)
      }
      reader.onload = () => {
        Promise.resolve(parse(reader.result))
          .then(svgTree => {
            delete svgTree.root.attrs['data-name']
            delete svgTree.root.attrs.id
            delete svgTree.root.attrs['xmlns:xlink']
            delete svgTree.root.attrs.x
            delete svgTree.root.attrs.y
            delete svgTree.root.attrs.style
            delete svgTree.root.attrs['xml:space']
            delete svgTree.root.attrs.version

            let width = 96
            let height = 96

            if (svgTree.root.attrs.viewBox) {
              width = svgTree.root.attrs.viewBox.split(' ')[2]
              height = svgTree.root.attrs.viewBox.split(' ')[3]
            } else if (svgTree.root.attrs.width) {
              width = svgTree.root.attrs.width
              height = svgTree.root.attrs.height
            }

            svgTree.root.attrs.width = 96
            svgTree.root.attrs.height = 96

            svgTree.root.content.unshift({ tag: 'rect', attrs: { width: width, height: height, fill: 'none' } })

            const defs = svgTree.select('defs')[0]
            if (defs) {
              svgTree.root.content.splice(svgTree.root.content.indexOf(defs), 1)
            }
            const title = svgTree.select('title')[0]
            if (title) {
              svgTree.root.content.splice(svgTree.root.content.indexOf(title), 1)
            }
            const style = svgTree.select('style')[0]
            if (style) {
              svgTree.root.content.splice(svgTree.root.content.indexOf(style), 1)
            }

            return svgTree
          })
          .then(svgTree => render(svgTree.root))
          .then(svgHtml => {
            this.$emit('addIndexer')
            const width = 96
            const height = 96
            var canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height
            var ctx = canvas.getContext('2d')
            var img = document.createElement('img')
            img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgHtml))))
            img.onload = () => {
              ctx.clearRect(0, 0, width, height)
              ctx.fillStyle = 'white'
              ctx.fillRect(0, 0, width, height)
              ctx.drawImage(img, 0, 0, width, height)
              const jpegString = canvas.toDataURL('image/jpeg')
              getAverageColor(canvas.toDataURL('image/png')).then(color => {
                this.modules.push(
                  {
                    fileName: fileName,
                    selected: false,
                    svgString: svgHtml,
                    tags: tags.filter(tag => tag !== ''),
                    dataUri: 'data:image/svg+xml;base64,' + window.btoa(reader.result),
                    jpegString: color.hex === '#000000' ? 'data:image/svg+xml;base64,' + window.btoa(reader.result) : jpegString
                  })
              })
            }
            this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1)
            this.$emit('input', this.modules)
          })
      }
    },
    change (e) {
      this.uploadingFiles = Array.from(e.target.files)

      this.uploadingFiles.forEach(file => {
        this.uploadFile(file)
      })
    },
    remove (index) {
      if (this.modificator !== 'Demo') {
        if (this.modificator !== 'Libraries') {
          if (this.currentKey === 'alt') {
            this.modules = []
          } else {
            this.modules.splice(index, 1)
          }
          this.$emit('input', this.modules)
        }
      }
      // } else {
      //   this.dselected = []
      // }
    }
  }
}
</script>
