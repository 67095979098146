<template>
    <div class="c-section c-palettes" v-if="modificator !== 'Demo' || palettes.length">
        <div v-if=" modificator !== 'Demo'"  class="c-section-heading">
            <p class="c-section-title">Palettes</p>
        </div>

        <ul class="c-section-list">
            <li
                v-for="(palette, paletteIndex) in palettes"
                :key="`palette${paletteIndex}`"
                class="c-section-list__item"
                :class="palette.displayed === false?'y_hidden':''"
            >
                <div class="c-palettes-stack">
                    <a
                        href="#"
                        class="c-palettes-link"
                        :class="{ 'is-active': palette.selected }"
                        @contextmenu.prevent="removePalette(paletteIndex)"
                        @click.prevent="selectPalette(paletteIndex)"
                    >
                        <div
                            v-for="(color, colorIndex) in palette.colors"
                            :key="`color${colorIndex}`"
                            class="c-palettes-item"
                            :style="{ backgroundColor: color, width: (100/palette.colors.length) + '%' }"
                        />
                    </a>
                  <span v-if="palette.favorite"
                  style="position: absolute;right: 5px;top: 0;">
                    <svg viewBox="0 -10 511.98685 511" width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0" fill="#ffc107"/></svg>
                  </span>
                    <a
                      v-if="modificator !== 'Libraries'"
                        href="#"
                        class="c-palettes-edit"
                        @click.prevent="edit(paletteIndex)"
                    ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.27097 13.0486L13.6125 0.707107L15.2929 2.38753L2.9514 14.7291L0.693462 15.3066L1.27097 13.0486Z" stroke="#444444"/>
                    </svg></a>
                </div>
            </li>

            <li v-if="modificator !== 'Front' && modificator !== 'Libraries' && modificator !== 'Demo'" class="c-section-list__item">
                <div  class="c-section-add c-palettes-add">
                    <a
                        href=""
                        class="c-section-add__link"
                        @click.prevent="editablePalette = []"
                    >
                        <span class="c-section-add__link-inner">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="12.5" y1="2.18557e-08" x2="12.5" y2="24" stroke="#444444"/>
<line x1="24" y1="12.5" x2="-4.37114e-08" y2="12.5" stroke="#444444"/>
</svg>
                        </span>
                    </a>
                </div>
              <div v-if="modificator !== 'Front' && modificator !== 'Libraries'  && modificator !== 'Demo'" ><input
                id="palettes_upload"
                ref="fileInput"
                class="c-section-add__input"
                type="file"
                accept=".xml"
                multiple
                @change="setXML"
              >
              <label for="palettes_upload" >
                        <span class="custom_button">
                          XML
                        </span>
              </label>
              </div>
              <div v-if="modificator !== 'Front' && modificator !== 'Libraries'  && modificator !== 'Demo'" ><input
                id="multiple_palettes_upload"
                ref="fileInput"
                class="c-section-add__input"
                type="file"
                accept=".txt"
                multiple
                @change="setMultipleXML($event,'},{')"
              >
              <label for="multiple_palettes_upload" >
                        <span class="custom_button">
                         mltpl },{
                        </span>
              </label>
              </div>
              <div v-if="modificator !== 'Front' && modificator !== 'Libraries'  && modificator !== 'Demo'" ><input
                id="multiple1_palettes_upload"
                ref="fileInput"
                class="c-section-add__input"
                type="file"
                accept=".txt"
                multiple
                @change="setMultipleXML($event,';')"
              >
              <label for="multiple1_palettes_upload" >
                        <span class="custom_button">
                         mltpl ;
                        </span>
              </label>
              </div>
            </li>
          <li v-if="modificator !== 'Front' && modificator !== 'Libraries' && modificator !== 'Demo'"  class="c-section-list__item">
            <input  class="black-text" style="margin-bottom: 100px;" type="text" v-model.lazy="inputPalette" placeholder="put palettes as dataset here">
          </li>
        </ul>

        <CreatePalette
            :palette="editablePalette"
            @save="save"
            @remove="remove"
            @close="close"
        />
    </div>
</template>

<script>
import CreatePalette from '../CreatePalette.vue'
// import { parse } from 'postsvg'

export default {
  components: {
    CreatePalette
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    modificator: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      palettes: [],
      editablePalette: null,
      editableIndex: null,
      inputPalette: null,
      uploadingFiles: []
    }
  },
  watch: {
    value () {
      this.palettes = this.value
    },
    inputPalette (value) {
      if (this.inputPalette.length > 0) {
        this.addPalettes(value)
        this.inputPalette = []
      }
    }
  },

  mounted () {
    this.palettes = this.value
  },
  methods: {
    setXML (e) {
      this.uploadingFiles = Array.from(e.target.files)
      this.uploadingFiles.forEach(file => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => {
          this.addPalettes(reader.result)
        }
      })
    },
    setMultipleXML (e, splt) {
      this.uploadingFiles = Array.from(e.target.files)
      this.uploadingFiles.forEach(file => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => {
          this.addMultiplePalettes(reader.result, splt)
        }
      })
    },
    removePalette (index) {
      if (this.modificator !== 'Demo') {
        if (this.modificator !== 'Libraries') {
          this.palettes.splice(index, 1)
        } else {
          this.$emit('setFavorites', index)
        }
      }
    },
    addMultiplePalettes (data, splt) {
      const dataArray = data.split(splt)
      dataArray.forEach(d => {
        const dy = d.match(/#[\da-fA-F]{3,8}/g)
        if (dy.length) {
          this.palettes.push({ colors: dy, selected: false })
        }
      })
    },
    addPalettes (data) {
      const dataArray = data.match(/#[\da-fA-F]{3,8}/g)
      if (dataArray.length) {
        this.palettes.push({ colors: dataArray, selected: false })
      }
    },
    selectPalette (index) {
      if (this.modificator !== 'Demo') {
        this.palettes.forEach((palette, paletteIndex) => {
          if (paletteIndex === index) {
            palette.selected = !palette.selected
          } else {
            if (this.modificator !== 'Libraries') {
              palette.selected = false
            }
          }
        })
      }

      this.$emit('input', this.palettes)
    },
    save (palette) {
      if (this.editableIndex === null) {
        this.palettes.push({ colors: palette, selected: false })
      } else {
        this.palettes[this.editableIndex].colors = palette
      }

      this.$emit('input', this.palettes)
    },
    remove () {
      if (this.editableIndex !== null) {
        this.palettes.splice(this.editableIndex, 1)
      }

      this.$emit('input', this.palettes)
    },
    edit (index) {
      this.editablePalette = this.palettes[index].colors
      this.editableIndex = index
    },
    close () {
      this.editablePalette = null
      this.editableIndex = null
    }
  }
}
</script>
