<template>
    <div ref="pickr" />
</template>

<script>
import Pickr from '@simonwep/pickr'

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: '#000'
    },
    swatches: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      pluginPickr: null
    }
  },
  computed: {
    nodePickr () {
      return this.$refs.pickr
    }
  },
  watch: {
    value (value) {
      this.setColor()
    },
    swatches (newValue) {
      const callback = () => {
        if (newValue) {
          newValue.forEach((color, index) => {
            this.pluginPickr.addSwatch(color)
          })
        }
      }

      let itemsProcessed = 0

      if (this.pluginPickr && this.pluginPickr._swatchColors.length) {
        const array = JSON.parse(JSON.stringify(this.pluginPickr._swatchColors))
        array.forEach((item, index) => {
          this.pluginPickr.removeSwatch(0)
          itemsProcessed++
          if (itemsProcessed === array.length) {
            callback()
          }
        })
      } else {
        callback()
      }
    }
  },
  mounted () {
    this.initPickr()
  },
  beforedestroy () {
    if (this.pluginPickr) {
      this.pluginPickr.destroyAndRemove()
    }
  },
  methods: {
    initPickr () {
      this.pluginPickr = Pickr.create({
        el: this.nodePickr,
        swatches: this.swatches,
        theme: 'nano',
        default: this.value,
        outputPrecision: 1,
        components: {

          // Main components
          preview: true,
          hue: true,

          interaction: {
            input: true,
            save: true,
            clear: true
          }
        },
        i18n: {
          'btn:clear': 'Remove'
        }
      })

      this.pluginPickr.on('init', this.setColor)
        .on('show', this.shown)
        .on('clear', this.clear)
        .on('save', this.save)

      this.pluginPickr._root.interaction.result.addEventListener('change', (e) => {
        const value = e.target.value
        if (this.isHexColor(value)) {
          this.pluginPickr.setColor(`#${value}`, true)
        }
      })
    },
    shown () {
      if (this.pluginPickr && this.pluginPickr._root && this.pluginPickr._root.interaction && this.pluginPickr._root.interaction.result) {
        setTimeout(() => {
          this.pluginPickr._root.interaction.result.addEventListener('focus', e => {
            e.target.select()
          })
          this.pluginPickr._root.interaction.result.addEventListener('keydown', e => {
            e.stopPropagation()
          })
          this.pluginPickr._root.interaction.result.focus()
        }, 100)
      }
    },
    setColor () {
      if (this.pluginPickr && this.value) {
        this.pluginPickr.setColor(this.value)
      }
    },
    isHexColor (hex) {
      return typeof hex === 'string' &&
                hex.length === 6 &&
                !isNaN(Number('0x' + hex))
    },
    save (color, instance) {
      if (this.pluginPickr) {
        this.pluginPickr.hide()
      }
      if (color) {
        this.$emit('input', color.toHEXA().toString())
      }
    },
    clear () {
      // this.pluginPickr.setColor()
      this.$emit('clear')
      // this.setColor()
    }
  }
}
</script>
