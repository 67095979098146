import firebase from 'firebase/app'

export default {
  state: {
    info: {},
    projects: {},
    lastProject: '',
    prid: '',
    library: [],
    field: '',
    logic: '',
    data: {}
  },
  computed: {
    state: function () { return this.$store.state }
  },
  mutations: {
    setInfo (state, info) {
      state.info = info
    },
    setLibraryModules (state, data) {
      state.library.modules = data
    },
    setLibraryPalettes (state, data) {
      state.library.palettes = data
    },
    clearInfo (state) {
      state.info = {}
    },
    setProjects  (state, projects) {
      state.projects = projects
    },
    setTheProject  (state, prid) {
      state.prid = prid
    },
    setLastProject  (state, prid) {
      state.lastProject = prid
    },
    theDatalogic (state, data) {
      state.logic = data
    },
    theDatafield (state, data) {
      state.field = data
    },
    setData (state, data) {
      state.data = data
    }
  },
  actions: {
    async fetchInfo ({ dispatch, commit }) {
      try {
        const uid = await dispatch('getUid')
        const info = (await firebase.database().ref(`/users/${uid}/info`).once('value')).val()
        commit('setInfo', info)
      } catch (e) {

      }
    },
    async fetchProjects ({ dispatch, commit }) {
      const uid = await dispatch('getUid')
      const proj = (await firebase.database().ref(`/users/${uid}/project`).once('value')).val()
      commit('setProjects', proj)
    },
    async getLastProject ({ dispatch, commit }) {
      const uid = await dispatch('getUid')
      const proj = (await firebase.database().ref(`/users/${uid}/lastPrj`).once('value')).val()
      commit('setLastProject', proj)
    },
    async getUsedItems ({ dispatch }, data) {
      const prid = data.prid
      const type = data.type
      const uid = await dispatch('getUid')
      const items = JSON.parse((await firebase.database().ref(`/users/${uid}/resources/${prid}/${type}`).once('value')).val())
      const itemsIds = []
      if (items !== null && items.length) {
        items.forEach(item => itemsIds.push(item.indexer))
      }
      return itemsIds
    },
    async getTheModulesLibrary ({ dispatch, commit }) {
      const dbs = firebase.database()
      function IsJsonString (str) {
        try {
          JSON.parse(str)
        } catch (e) {
          return false
        }
        return true
      }
      const dbTime = (await dbs.ref('/updates/modules/time').once('value')).val()
      const time = localStorage.modulesTime ? localStorage.modulesTime : 0
      if (Number(dbTime) > Number(time)) {
        localStorage.modulesTime = dbTime
        await dbs.ref('/mod_keys').once('value', snap => {
          const FireData = snap.val()
          const data = []
          if (snap.val()) {
            for (const [key, value] of Object.entries(FireData)) {
              if (IsJsonString(value.tags)) {
                data.push({
                  tags: JSON.parse(value.tags),
                  fileName: value.fileName,
                  indexer: key
                })
              } else {
                dbs.ref(`/mod_keys/${key}/tags`).set(JSON.stringify(['other']))
                data.push({
                  tags: ['other'],
                  fileName: value.fileName,
                  indexer: key
                })
              }
            }
          }
          localStorage.modulesStorage = JSON.stringify(data)
        })
      }
      const fullTime = (await dbs.ref('/updates/full/time').once('value')).val()
      const ftime = localStorage.modulesFullTime ? localStorage.modulesFullTime : 0
      if (Number(fullTime) > Number(ftime)) {
        const data = []
        await firebase.database().ref('/fullmodules').orderByChild('upd').startAt(Number(ftime)).once('value', snap => {
          const FireData = snap.val()
          if (snap.val()) {
            for (const [key, value] of Object.entries(FireData)) {
              data.push({
                svgString: value.svgString,
                tags: value.tags,
                dataUri: value.dataUri,
                id: key
              })
            }
          }
        })
        let db
        const dbReq = indexedDB.open('db', 1)
        dbReq.onupgradeneeded = (event) => {
          db = event.target.result
          db.createObjectStore('modules', { keyPath: 'id' })
        }
        dbReq.onsuccess = (event) => {
          db = event.target.result
          data.forEach(item => {
            addStickyNote(db, item)
          })
        }
        localStorage.modulesFullTime = fullTime
        const addStickyNote = (db, message) => {
          const tx = db.transaction(['modules'], 'readwrite')
          const store = tx.objectStore('modules')
          store.put(message)
          tx.oncomplete = () => {
          }
          tx.onerror = (event) => {
            console.log('db error' + event.target.errorCode)
          }
        }
        dbReq.onerror = (event) => {
          alert('error opening database ' + event.target.errorCode)
        }
      }
    },
    async saveDataToDatabase ({ dispatch, commit }, obj) {
      const data = JSON.stringify(obj.data)
      const type = obj.type
      const name = obj.name
      const uid = await dispatch('getUid')
      const autor = (await firebase.database().ref(`/users/${uid}/info/name`).once('value')).val()
      const db = firebase.database()
      db.ref(`/${type}`).push({
        autor,
        name,
        data
      })
    },
    async getThePalettesLibrary ({ dispatch, commit }, user) {
      let path = '/palettes'
      if (user) {
        const uid = await dispatch('getUid')
        path = `users/${uid}/${path}`
      }
      await firebase.database().ref(`/${path}`).once('value', snap => {
        const FireData = snap.val()
        const data = []
        if (snap.val()) {
          for (const [key, value] of Object.entries(FireData)) {
            data.push({
              colors: value.colors,
              indexer: key,
              selected: false
            })
          }
        }
        commit('setLibraryPalettes', data)
      })
    },
    async deleteListElementFromDatabase ({ dispatch }, obj) {
      await firebase.database().ref(`/${obj.type}/${obj.id}`).remove()
    },
    async getDataListFromDatabase ({ dispatch, commit }, type) {
      await firebase.database().ref(`/${type}`).once('value', snap => {
        const FireData = snap.val()
        const data = []
        if (snap.val()) {
          for (const [key, value] of Object.entries(FireData)) {
            data.push({
              data: JSON.parse(value.data),
              name: value.name,
              key: key,
              autor: value.autor
            })
          }
        }
        commit('theData' + type, JSON.stringify(data))
      })
    },
    async setNewModules ({ dispatch }, data) {
      const uid = await dispatch('getUid')
      await firebase.database().ref(`/users/${uid}/resources/${data.prid}/`).update({ modules: data.json })
    },
    async setNewPalettes ({ dispatch, commit }, data) {
      const uid = await dispatch('getUid')
      data.json.forEach(mod => {
        mod.selected = false
        mod.favorite = false
      })
      data.json = JSON.stringify(data.json)
      await firebase.database().ref(`/users/${uid}/resources/${data.prid}/`).update({ palettes: data.json })
    },
    async enterTheProject ({ dispatch, commit }, projecttime) {
      const uid = await dispatch('getUid')
      await firebase.database().ref(`/users/${uid}/lastPrj`).set(projecttime)
      commit('setLastProject', projecttime)
      await firebase.database().ref(`/users/${uid}/resources/${projecttime}/`).once('value', snap => {
        const FireData = snap.val()
        if (snap.val()) {
          for (const [key, value] of Object.entries(FireData)) {
            localStorage.setItem(key, value)
          }
        }
      })
    },
    async enterTheModules ({ dispatch }) {
      await firebase.database().ref('/modules/items').once('value', snap => {
        const FireData = snap.val()
        if (snap.val()) {
          for (const [key, value] of Object.entries(FireData)) {
            localStorage.setItem(key, value)
          }
        }
      })
    },
    async saveChangesToTheModules ({ dispatch, commit }, data) {
      try {
        const db = firebase.database()
        const tags = []
        await db.ref('/tags').once('value', snap => {
          const FireData = snap.val()
          if (snap.val()) {
            for (const value of Object.entries(FireData)) {
              tags.push(value[1])
            }
          }
        })
        let newTags = []
        const d = new Date()
        const now = d.getTime()
        await data.forEach(modul => {
          const ntag = modul.tags.filter(x => !tags.includes(x))
          if (ntag.length > 0) { newTags = newTags.concat(ntag) }
          const sample = db.ref('/fullmodules').push({
            svgString: modul.svgString,
            dataUri: modul.jpegString,
            upd: now
          })
          const theKey = sample.key
          db.ref('/mod_keys').child(theKey).set({
            tags: JSON.stringify(modul.tags),
            fileName: modul.fileName
          })
        })
        newTags = newTags.filter((item, index) => newTags.indexOf(item) === index)
        newTags.forEach(tag => {
          db.ref('/tags').push(tag)
        })
        await db.ref('/updates/modules/time').set(now)
        await db.ref('/updates/full/time').set(now)
      } catch (e) {
        commit('setError', e)
        console.log(e)
        throw e
      }
    },
    async trigger ({ dispatch }, type) {
      const db = firebase.database()
      const d = new Date()
      const now = d.getTime()
      if (Number(type) === 1) {
        await db.ref('/updates/modules/time').set(now)
      }
      if (Number(type) === 2) {
        await db.ref('/updates/full/time').set(now)
      }
      if (Number(type) === 3) {
        await db.ref('/updates/modules/time').set(now)
        await db.ref('/updates/full/time').set(now)
      }
    },
    async saveChangesToThePalettes ({ dispatch, commit }, obj) {
      const data = obj.data
      let path = '/palettes'
      if (obj.user) {
        const uid = await dispatch('getUid')
        path = `users/${uid}/${path}`
      }
      try {
        await data.forEach(palette => {
          const db = firebase.database()
          db.ref('/' + path).push({
            colors: palette.colors
          })
        })
      } catch (e) {
        commit('setError', e)
        console.log(e)
        throw e
      }
    },
    async register ({ dispatch, commit }, { email, password, name }) {
      try {
        await firebase.auth().createUserWithEmailAndPassword(email, password)
        const uid = await dispatch('getUid')
        await firebase.database().ref(`/users/${uid}/info`).set({
          name
        })
      } catch (e) {
        commit('setError', e)
        console.log(e)
        throw e
      }
    },
    async newProject ({ dispatch, commit }, obj) {
      const uid = await dispatch('getUid')
      await firebase.database().ref(`/users/${uid}`).update({ project: obj.json })
      await firebase.database().ref(`/users/${uid}/lastPrj`).set(obj.date)
    },
    async saveMData ({ dispatch, commit }, data) {
      const uid = await dispatch('getUid')
      const toFireBase = []
      for (let i = 0; i < data.data.length; i++) {
        const storagedData = ['background', 'aggregatedField', 'count', 'extensionType', 'fullRandom', 'grid', 'rastrMultiplier', 'settingsAggregator', 'staticSettingsAggregator', 'hotGenerating', 'length', 'modules', 'name', 'palettes', 'rotate', 'spaces', 'limited']
        if (storagedData.includes(data.data.key(i))) { toFireBase[data.data.key(i)] = data.data.getItem(data.data.key(i)) }
      }
      try {
        await firebase.database().ref(`/users/${uid}/resources/${data.prid}/`).set(Object.assign({}, toFireBase))
      } catch (e) {
        commit('setError', e)
        console.log(e)
        throw e
      }
    },
    async getDataFromTable ({ dispatch, commit }, type) {
      await firebase.database().ref(`/${type}`).once('value', snap => {
        const FireData = snap.val()
        const data = []
        if (snap.val()) {
          for (const [key, value] of Object.entries(FireData)) {
            data.push({
              data: value,
              key: key
            })
          }
        }
        commit('setData', data)
      })
    },
    async getValueFromTable ({ dispatch, commit }, obj) {
      const table = obj.table
      const id = obj.id
      const data = (await firebase.database().ref(`/users/${table}/${id}`).once('value')).val()
      commit('setData', JSON.stringify(data))
    },
    async getDirectValue ({ dispatch, commit }, table) {
      const data = (await firebase.database().ref(table).once('value')).val()
      commit('setData', data)
    },
    async deleteDataFromTable ({ dispatch }, obj) {
      const table = obj.table
      const id = obj.id
      await firebase.database().ref(`/${table}/${id}`).remove()
    },
    async saveDataToTable ({ dispatch }, obj) {
      const data = obj.data
      const db = firebase.database()
      let path = `/${obj.table}`
      if (obj.user) {
        const uid = await dispatch('getUid')
        path = `/users/${uid}/${obj.table}`
      }
      db.ref(path).push(data)
    },
    async packageTagEditor ({ dispatch }, obj) {
      const db = firebase.database()
      obj.forEach(data => {
        db.ref(`/mod_keys/${data.key}/tags`).set(data.tags)
      })
      const d = new Date()
      const n = d.getTime()
      await db.ref('/updates/modules/time').set(n)
    },
    async getAllCollectionData ({ dispatch, commit }) {
      const data = []
      await firebase.database().ref('/collections').once('value', snap => {
        const FireData = snap.val()
        if (snap.val()) {
          for (const [key, value] of Object.entries(FireData)) {
            data.push({
              keys: JSON.parse(value.keys),
              name: value.name,
              private: false,
              key: key
            })
          }
        }
      })
      const uid = await dispatch('getUid')
      await firebase.database().ref(`users/${uid}/collections`).once('value', snap => {
        const FireData = snap.val()
        if (snap.val()) {
          for (const [key, value] of Object.entries(FireData)) {
            data.push({
              amount: value.amount,
              keys: JSON.parse(value.keys),
              name: value.name,
              private: true,
              key: key
            })
          }
        }
        commit('setData', data)
      })
    },
    async putArrayToTable ({ dispatch }, obj) {
      let path = obj.path
      if (obj.user) {
        const uid = await dispatch('getUid')
        path = `users/${uid}/${obj.path}`
      }
      let data = (await firebase.database().ref(path).once('value')).val()
      data = JSON.parse(data)
      if (data !== null) {
        data = data.concat(obj.array)
        data = data.filter((item, index) => data.indexOf(item) === index)
      } else {
        data = obj.array
      }
      await firebase.database().ref(path).set(JSON.stringify(data))
    },
    async getArrayFromTableJson ({ dispatch, commit }, obj) {
      const user = obj.user
      let path = obj.path
      if (user) {
        const uid = await dispatch('getUid')
        path = `users/${uid}/${obj.path}`
      }
      const data = (await firebase.database().ref(path).once('value')).val()
      commit('setData', JSON.parse(data))
    },
    async deleteArrayItem ({ dispatch }, obj) {
      let path = obj.path
      const key = obj.key
      const user = obj.user
      if (user) {
        const uid = await dispatch('getUid')
        path = `users/${uid}/${obj.path}`
      }
      let data = (await firebase.database().ref(path).once('value')).val()
      data = JSON.parse(data)
      data.splice(data.indexOf(key), 1)
      await firebase.database().ref(path).set(JSON.stringify(data))
    },
    async deleteItemFromTable ({ dispatch }, obj) {
      let path = obj.path
      const user = obj.user
      if (user) {
        const uid = await dispatch('getUid')
        path = `users/${uid}/${obj.path}`
      }
      await firebase.database().ref(path).remove()
    },
    async getSingleModulesDataFromTable ({ dispatch, commit }, key) {
      const data = (await firebase.database().ref(`/fullmodules/${key}`).once('value')).val()
      data.tags = JSON.parse((await firebase.database().ref(`/mod_keys/${key}/tags`).once('value')).val())
      commit('setData', data)
    },
    async setNewDataToTable ({ dispatch }, obj) {
      const type = obj.type
      const data = obj.data
      await firebase.database().ref(`/${type}`).set(data)
    },
    async setPersonalDataToTable ({ dispatch }, obj) {
      const data = obj.data
      const uid = await dispatch('getUid')
      const path = `users/${uid}/${obj.type}`
      await firebase.database().ref(`/${path}`).set(data)
    }

  },
  getters: {
    info: s => s.info,
    projects: s => s.projects,
    lastProject: s => s.lastProject,
    prid: s => s.prid,
    library: s => s.library,
    field: s => s.field,
    logic: s => s.logic,
    data: s => s.data
  }
}
