<template>
  <div class="contentDR">
    <div :id="'mysliderDR'+id" :se-min="minThreshold" :se-step="step" :se-min-value="min" :se-max-value="max" :se-max="maxThreshold" class="sliderDR">
      <div class="sliderDR-touch-left">
        <span></span>
      </div>
      <div class="sliderDR-touch-right">
        <span></span>
      </div>
      <div class="sliderDR-line">
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    minThreshold: {
      type: Number,
      default: -100
    },
    maxThreshold: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    id: {
      type: String,
      default: 'simple'
    }
  },
  data: function () {
    return {
      instance: undefined
    }
  },
  mounted: function () {
    this.instance = new this.ZbRangesliderDR('mysliderDR' + this.id)
    this.instance.onChange = (min, max) => this.updateValues(min, max)
  },
  destroyed: function () {

  },
  methods: {
    updateValues: function (min, max) {
      this.$emit('update:min', min)
      this.$emit('update:max', max)
    },
    ZbRangesliderDR: function (id) {
      var self = this
      var startX = 0; var x = 0

      // retrieve touch button
      var sliderDR = document.getElementById(id)
      var touchLeft = sliderDR.querySelector('.sliderDR-touch-left')
      var touchRight = sliderDR.querySelector('.sliderDR-touch-right')
      var lineSpan = sliderDR.querySelector('.sliderDR-line span')

      // get some properties
      var min = parseFloat(sliderDR.getAttribute('se-min'))
      var max = parseFloat(sliderDR.getAttribute('se-max'))

      // retrieve default values
      var defaultMinValue = min
      if (sliderDR.hasAttribute('se-min-value')) {
        defaultMinValue = parseFloat(sliderDR.getAttribute('se-min-value'))
      }
      var defaultMaxValue = max

      if (sliderDR.hasAttribute('se-max-value')) {
        defaultMaxValue = parseFloat(sliderDR.getAttribute('se-max-value'))
      }

      // check values are correct
      if (defaultMinValue < min) {
        defaultMinValue = min
      }

      if (defaultMaxValue > max) {
        defaultMaxValue = max
      }

      if (defaultMinValue > defaultMaxValue) {
        defaultMinValue = defaultMaxValue
      }

      var step = 0.0

      if (sliderDR.getAttribute('se-step')) {
        step = Math.abs(parseFloat(sliderDR.getAttribute('se-step')))
      }

      // normalize flag
      var normalizeFact = 26

      self.sliderDR = sliderDR
      self.reset = function () {
        touchLeft.style.left = '0px'
        touchRight.style.left = (sliderDR.offsetWidth - touchLeft.offsetWidth) + 'px'
        lineSpan.style.marginLeft = '0px'
        lineSpan.style.width = (sliderDR.offsetWidth - touchLeft.offsetWidth) + 'px'
        startX = 0
        x = 0
      }

      self.setMinValue = function (minValue) {
        var ratio = ((minValue - min) / (max - min))
        touchLeft.style.left = Math.ceil(ratio * (sliderDR.offsetWidth - (touchLeft.offsetWidth + normalizeFact))) + 'px'
        lineSpan.style.marginLeft = touchLeft.offsetLeft + 'px'
        lineSpan.style.width = (touchRight.offsetLeft - touchLeft.offsetLeft) + 'px'
        sliderDR.setAttribute('se-min-value', minValue)
      }

      self.setMaxValue = function (maxValue) {
        var ratio = ((maxValue - min) / (max - min))
        touchRight.style.left = Math.ceil(ratio * (sliderDR.offsetWidth - (touchLeft.offsetWidth + normalizeFact)) + normalizeFact) + 'px'
        lineSpan.style.marginLeft = touchLeft.offsetLeft + 'px'
        lineSpan.style.width = (touchRight.offsetLeft - touchLeft.offsetLeft) + 'px'
        sliderDR.setAttribute('se-max-value', maxValue)
      }

      // initial reset
      self.reset()

      // usefull values, min, max, normalize fact is the width of both touch buttons
      var maxX = sliderDR.offsetWidth - touchRight.offsetWidth
      var selectedTouch = null
      var initialValue = (lineSpan.offsetWidth - normalizeFact)

      // set defualt values
      self.setMinValue(defaultMinValue)
      self.setMaxValue(defaultMaxValue)

      // setup touch/click events
      function onStart (event) {
        // Prevent default dragging of selected content
        event.preventDefault()
        var eventTouch = event

        if (event.touches) {
          eventTouch = event.touches[0]
        }

        if (this === touchLeft) {
          x = touchLeft.offsetLeft
        } else {
          x = touchRight.offsetLeft
        }

        startX = eventTouch.pageX - x
        selectedTouch = this
        document.addEventListener('mousemove', onMove)
        document.addEventListener('mouseup', onStop)
        document.addEventListener('touchmove', onMove)
        document.addEventListener('touchend', onStop)
      }

      function onMove (event) {
        var eventTouch = event

        if (event.touches) {
          eventTouch = event.touches[0]
        }

        x = eventTouch.pageX - startX

        if (selectedTouch === touchLeft) {
          if (x > (touchRight.offsetLeft - selectedTouch.offsetWidth + 10)) {
            x = (touchRight.offsetLeft - selectedTouch.offsetWidth + 10)
          } else if (x < 0) {
            x = 0
          }

          selectedTouch.style.left = x + 'px'
        } else if (selectedTouch === touchRight) {
          if (x < (touchLeft.offsetLeft + touchLeft.offsetWidth - 10)) {
            x = (touchLeft.offsetLeft + touchLeft.offsetWidth - 10)
          } else if (x > maxX) {
            x = maxX
          }
          selectedTouch.style.left = x + 'px'
        }

        // update line span
        lineSpan.style.marginLeft = touchLeft.offsetLeft + 'px'
        lineSpan.style.width = (touchRight.offsetLeft - touchLeft.offsetLeft) + 'px'

        // write new value
        calculateValue()

        // call on change
        if (sliderDR.getAttribute('on-change')) {
          // eslint-disable-next-line no-new-func
          var fn = new Function('min, max', sliderDR.getAttribute('on-change'))
          fn(sliderDR.getAttribute('se-min-value'), sliderDR.getAttribute('se-max-value'))
        }

        if (self.onChange) {
          self.onChange(sliderDR.getAttribute('se-min-value'), sliderDR.getAttribute('se-max-value'))
        }
      }

      function onStop (event) {
        document.removeEventListener('mousemove', onMove)
        document.removeEventListener('mouseup', onStop)
        document.removeEventListener('touchmove', onMove)
        document.removeEventListener('touchend', onStop)

        selectedTouch = null

        // write new value
        calculateValue()

        // call did changed
        if (sliderDR.getAttribute('did-changed')) {
          // eslint-disable-next-line no-new-func
          var fn = new Function('min, max', sliderDR.getAttribute('did-changed'))
          fn(sliderDR.getAttribute('se-min-value'), sliderDR.getAttribute('se-max-value'))
        }

        if (self.didChanged) {
          self.didChanged(sliderDR.getAttribute('se-min-value'), sliderDR.getAttribute('se-max-value'))
        }
      }

      function calculateValue () {
        var newValue = (lineSpan.offsetWidth - normalizeFact) / initialValue
        var minValue = lineSpan.offsetLeft / initialValue
        var maxValue = minValue + newValue

        minValue = minValue * (max - min) + min
        maxValue = maxValue * (max - min) + min
        if (step !== 0.0) {
          var multi = Math.floor((minValue / step))
          minValue = step * multi

          multi = Math.floor((maxValue / step))
          maxValue = step * multi
        }

        sliderDR.setAttribute('se-min-value', minValue)
        sliderDR.setAttribute('se-max-value', maxValue)
      }

      // link events
      touchLeft.addEventListener('mousedown', onStart)
      touchRight.addEventListener('mousedown', onStart)
      touchLeft.addEventListener('touchstart', onStart)
      touchRight.addEventListener('touchstart', onStart)
    }
  }
}
</script>

<style>
.contentDR {
  width: 76%;
  margin-right: -16px;
}
.sliderDR {
  display: block;
  position: relative;
  height: 36px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.sliderDR .sliderDR-touch-left,
.sliderDR .sliderDR-touch-right {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: 36px;
  width: 36px;
  padding: 6px;
  z-index: 2;
}
.sliderDR .sliderDR-touch-left span,
.sliderDR .sliderDR-touch-right span {
  display: block;
  width: 100%;
  height: 100%;
  background: #26a69a;
  border: 4px solid #ffffff;
  border-radius: 50%;
}
.sliderDR .sliderDR-line {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: calc(100% - 36px);
  left: 18px;
  top: 16px;
  height: 3px;
  border-radius: 4px;
  background: #c2c0c2;
  z-index: 0;
  overflow: hidden;
}
.sliderDR .sliderDR-line span {
  display: block;
  height: 100%;
  width: 0%;
  background: #26a69a;
}
</style>
