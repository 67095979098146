<template>
    <div class="c-section c-field">
        <div class="c-section-heading">
            <p class="c-section-title">Field</p>
        </div>

        <div class="c-section-form">
            <ul class="c-section-list">
                <li class="c-section-list__item">
                    <input
                        :value="value[0]"
                        type="number"
                        class="form-control browser-default"
                        :min="min"
                        :max="max"
                        name="x"
                        @keypress="isNumber($event)"
                        @change="changeXCount($event.target.value)"

                    >
                </li>
                <li class="c-section-list__item">
                    <input
                        :value="value[1]"
                        type="number"
                        class="form-control browser-default"
                        :min="min"
                        :max="max"
                        name="y"
                        @keypress="isNumber($event)"
                        @change="changeYCount($event.target.value)"
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

  props: {
    value: {
      type: Array,
      required: true
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 32
    },
    currentKey: {
      type: [Number, String],
      required: false,
      default: null
    },
    gridIsFocused: {
      type: Boolean,
      required: true,
      default: false
    }

  },
  watch: {

    currentKey (value) {
      if (value === 173 || value === 189) {
        if (!this.gridIsFocused) { this.changeXCount(this.value[0] + 1) }
      }
      if (value === 61 || value === 187) {
        this.changeXCount(this.value[0] - 1)
      }
      if (value === 219) {
        this.changeYCount(this.value[1] + 1)
      }
      if (value === 221) {
        this.changeYCount(this.value[1] - 1)
      }
    }

  },
  methods: {
    isNumber (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    changeXCount (value) {
      let fixValue = Number(value)

      if (isNaN(fixValue)) {
        fixValue = this.min
      }

      if (fixValue <= this.min) {
        fixValue = this.min
      }

      if (fixValue >= this.max) {
        fixValue = this.max
      }

      this.$emit('input', [fixValue, this.value[1]])
    },
    changeYCount (value) {
      let fixValue = Number(value)

      if (isNaN(fixValue)) {
        fixValue = this.min
      }

      if (fixValue <= this.min) {
        fixValue = this.min
      }

      if (fixValue >= this.max) {
        fixValue = this.max
      }

      this.$emit('input', [this.value[0], fixValue])
    }
  }
}
</script>
