<template>
    <div class="c-section-form">
        <input
            type="number"
            class="form-control browser-default"
            :value="value"
            name="grid"
            :min="min"
            :max="max"
            @change="changeValue($event.target.value)"
            @focus="$emit('focus',true)"
            @blur="$emit('blur',true)"
            @keypress="inputClearValue($event,[])"
        >
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    min: {
      type: [Number, String],
      required: true
    },
    max: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return { e: true }
  },
  watch: {
    max (value) {
      if (value < this.value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    isNumber (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      const accepted = [8, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 190, 37, 38, 39, 40, 173, 189]
      if (!accepted.includes(charCode)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    changeValue (value) {
      let fixValue = Number(value)
      if (isNaN(fixValue)) {
        fixValue = this.min
      }

      if (fixValue <= this.min) {
        fixValue = this.min
      }

      if (fixValue >= this.max) {
        fixValue = this.max
      }

      this.$emit('input', fixValue)
    },
    inputClearValue ($event, accepted) {
      const defaultKeys = [8, 13, 46, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 190, 37, 38, 39, 40, 173, 189]
      const keyCode = $event.keyCode
      if (!defaultKeys.includes(keyCode) && !accepted.includes(keyCode)) {
        $event.preventDefault()
      }
      if (keyCode === 13) {
        this.$emit('blur', true)
      }
    }

  }
}
</script>
