<template>
  <ul class="sidenav app-sidenav" :class="{open: value}">

    <router-link
      v-for="link in links"
      :key="link.url"
      tag="li"
      active-class="active"
      :to="link.url"
      :exact="link.exact"
    >
      <a href="#" class="waves-effect waves-orange pointer">{{link.title}}</a>
    </router-link>
  </ul>
</template>

<script>
export default {
  props: ['value'],
  data: () => ({
    links: [
      { title: 'Projects', url: '/', exact: true },
      { title: 'Modules', url: '/modules', exact: true },
      { title: 'Palettes', url: '/privpalettes', exact: true },
      { title: 'Public Palettes', url: '/palettes', exact: true },
      { title: 'Empty project preset', url: '/preset', exact: true }
    ]
  })
}
</script>
