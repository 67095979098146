<template>
    <main id="app" class="c-app gen_layout">
      <Navbar @click="isOpen = !isOpen" />

      <div class="c-app-inner app-page">
        <div style="
        display:flex; position: fixed;
        width: 453px;
        justify-content:space-between;
        margin-bottom: 60px; top: 0;
        left: 10px;
        z-index: 1000;
        padding-top: 20px;">
          <div
            v-for="(tab,index) in tabs"
            :key="'tabs_'+index"
            @click="tabTogglerWasClicked(tab)"
            :class="'btn btn-outline btn-light tabs_toggler tt_'+(staticSettingsAggregator.activeTabs.includes(tab)?1:0)"
          >{{ tab }}
          </div>
        </div>

        <div class="c-app-sibebar" style=" margin-top: 56px; overflow-x: hidden; overflow-y: scroll; height: calc(100vh - 120px);">
          <div
            style="position:absolute; top:0; z-index: 99; background-color: #E5E5E5; margin-left: 0; width: 100%; height: 100vw;"
            v-if="hotkeyPanel"
          >
            <br>
            <h3>Hotkeys</h3>
            <p><b>Ctrl + Z</b> - step backward</p>
            <p><b>Ctrl + Shift + Z</b> (after Ctrl + Z) - step forward</p>
            <p><b>Ctrl + [0-9]</b> Set limited modules</p>
            <p><b>H</b> - Hotkeys</p>
            <p><b>D</b> - Save svg as file</p>
            <p><b>G</b> - Generate the svg canvas</p>
            <br>
            <h3>Modules</h3>
            <p><b>LBM click /LMB area select</b> - toggle chosen state</p>
            <p><b>RMB click / RMB area select</b> - delete modules</p>
            <p><b>Shift + LMB area select</b> - add area to chosen</p>
            <p><b>Ctrl + LMB area select</b> - remove area from chosen</p>
            <p><b>Ctrl+D</b> - deselect all</p>
            <p><b>Ctrl + RMB click</b> - delete all</p>
            <br>
            <h3>Palettes</h3>
            <p><b>LBM click</b> - toggle chosen state</p>
            <p><b>Shift + P</b> - fix current autoselected palette</p>
            <p><b>Ctrl+P</b> - deselect palette</p>
            <p><b>P</b> - choose and apply a random palette</p>
            <br>
            <h3>Canvas</h3>
            <p><b>"-", "=", "[" , "]"</b> - add/remove column/row</p>
            <p><b>hover + R</b> - rotation 90*</p>
            <p><b>hover + T</b> - horizontal and vertical reflection </p>
            <p><b>hover + S</b> - deselect all modules, select hovered</p>
            <p><b>hover + C</b> - recolor the canvas element</p>
            <br>
            <h3>Resizeable canvas elements</h3>
            <p><b>@1|1|4@5|2|2</b> @[coord ver]|[coord hor]|[size]</p>
            <p><b>(10:10)%2*3%5</b> ([field hor]:[field ver]%[size]*[amount]%[size]</p>
            <br>
            <h3>Seamless elements</h3>
            <p><b>90|1|3%180|2|2</b> [rot]|[refl 1-h,2-v]|[color in palette]</p>
          </div>
          <span v-if="generating">generating</span>
          <ul v-if="staticSettingsAggregator.activeTabs.includes('Field')" class="c-section-list">
            <li class="c-section-list__item c-params">
              <div class="c-section">
                <div class="c-section-heading">
                  <p class="c-section-title">background</p>
                </div>

                <ul class="c-section-list">
                  <li class="c-section-list__item c-params-sm">
                    <ul class="c-section-list">
                      <li class="c-section-list__item">
                        <div class="c-background">
                          <Background
                            v-model="background"
                            :palette="randomPalette"
                            :key="theFetchedData"
                          />
                        </div>
                      </li>
                      <li class="c-section-list__item">
                        <div class="c-grid">
                          <Grid
                            v-model="grid"
                            :min="-96"
                            :max="96"
                            @focus="gridIsFocused = true"
                            @blur="gridIsFocused = false"
                          />
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="c-section-list__item c-params-lg">
                    <div class="c-spaces">
                      <Spaces
                        v-model="spaces"
                        :current-key="currentKey"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li class="c-section-list__item c-random">
              <Random
                v-model="fullRandom"
                :key="theFetchedData"

              />
            </li>
            <li class="c-section-list__item c-rotate">
              <Rotate
                v-model="rotate"
                :key="theFetchedData"

              />
            </li>
            <li class="c-section-list__item c-hot">
              <HotGenerating
                v-model="hotGenerating"
                :key="theFetchedData"

              />
            </li>
            <li class="c-section-list__item c-field">
              <Field
                v-model="newCount"
                :min="1"
                :max="32"
                :current-key="currentKey"
                :grid-is-focused="gridIsFocused"
              />
            </li>
            <li style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title"> Max.modules </span><span
              class="c-section-title"> {{limited }}</span>
              <input v-model="limited" type="range" style="width: 70%;" min="0" max="9">
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.limitedFixed=!staticSettingsAggregator.limitedFixed"
              >{{ staticSettingsAggregator.limitedFixed ? '🔒' : '☣' }}</span>
            </li>
            <li style="width: 100%;">
              <div
                style="width: 100%; display: flex; margin-top: 10px;"
                v-for="(field,index) in aggregatedField"
                :key="`field${index}`"
              >
                <input
                  @keypress="inputClearValue($event,[])"

                  class="small_input"
                  type="number"
                  v-model="aggregatedField[index].size"
                  min="2"
                  :max="Math.min(count[0],count[1])"
                />
                <input
                  @keypress="inputClearValue($event,[])"

                  class="small_input"
                  type="number"
                  v-model="aggregatedField[index].count"
                  min="1"
                  :max="Math.min(count[0],count[1])"
                />
                <span
                  @click="aggregatedField.splice(index,1)"
                  class="small_simple_button"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg"><g transform="rotate(45,12,12)"><line
                  stroke-width="5" x1="12.5" y1="2.18557e-08" x2="12.5" y2="24" stroke="red"></line><line
                  stroke-width="5" x1="24" y1="12.5" x2="-4.37114e-08" y2="12.5" stroke="red"></line></g></svg></span>
              </div>
              <div
                style="width: 100%; display: flex; margin-top: 10px; margin-bottom:10px ">
                         <span
                           style="width: 189px; display:flex; justify-content: center"
                           @click="aggregatedField.push({size:2,count:1})"
                           class="aggregated_field_add_button"> add agregated fields</span>

              </div>
              <div
                style="width: 100%; display: flex; margin-top: 10px; margin-bottom:10px ">
                         <span
                           @click="showModal('sfield')"
                           style="width: 279px; display:flex; justify-content: center "
                           class=" bg_2 aggregated_field_add_button">Save field settings</span>
                <span
                  @click="showModal('mfield');
                                getLogicDataFromDB('field')"
                  style="width: 279px; display:flex; justify-content: center "
                  class=" bg_4 aggregated_field_add_button">Upload field settings</span>

              </div>
              <modal
                name="mfield"
                :adaptive="true"
                width="500"
                height="auto"
              >
                <div class="v--modal-inner">
                  <p class="h5 v--modal-title">Select the field settings from database</p>
                  <a
                    href="#"
                    class="v--modal-close"
                    @click.prevent="hideModal('mfield')"
                  >Close</a>
                  <table v-if="dbData.fieldData.length">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Created by</th>
                      <th>Delete</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(item, index) in dbData.fieldData"
                        :key="item.key">
                      <td>
                        <button
                          class="btn-small btn"
                          @click="applyFieldSettins(item.data); hideModal('mfield')"
                        >{{ item.name }}
                        </button>
                      </td>
                      <td>{{ item.autor }}</td>
                      <td>
                        <button @click="deleteListFromDB('field',item.key, index)" class="btn-small btn">
                          <i class="material-icons">delete</i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </modal>
              <modal
                name="sfield"
                :adaptive="true"
                width="500"
                height="auto"
              >
                <div class="v--modal-inner">
                  <p class="h5 v--modal-title">Enter name for your field settings</p>
                  <input type="text" v-model="dbData.fieldName" placeholder="logic name">
                  <button
                    class="btn-small btn"
                    @click="saveFieldSettingsToDB();hideModal('sfield');dbData.fieldName=''"
                  >SAVE
                  </button>
                  <a
                    href="#"
                    class="v--modal-close"
                    @click.prevent="hideModal('sfield')"
                  >Cancel</a>
                </div>
              </modal>
            </li>

          </ul>
          <ul v-if="staticSettingsAggregator.activeTabs.includes('Sliders')" class="c-section-list"
              :key="defaultRefresh">
            <li v-if="!settingsAggregator.seamless"
                style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title"> Transparent </span><span
              class="c-section-title"> {{ settingsAggregator.transparent }}%</span>
              <input v-model="settingsAggregator.transparent" type="range" style="width: 70%;" min="0" max="100">
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.transparentFixed=!staticSettingsAggregator.transparentFixed">{{
                  staticSettingsAggregator.transparentFixed ? '🔒' : '☣'
                }}</span>
            </li>
            <li v-else style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title"> Transparent </span><span
              class="c-section-title"> {{ settingsAggregator.transparent }}%</span>
              <input v-model="settingsAggregator.transparent" type="range" style="width: 70%;" step="100" min="0"
                     max="100">
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.transparentFixed=!staticSettingsAggregator.transparentFixed">{{
                  staticSettingsAggregator.transparentFixed ? '🔒' : '☣'
                }}</span>
            </li>
            <li style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title"> Gradient </span><span
              class="c-section-title"> {{ settingsAggregator.gradient }}%</span>
              <input v-model="settingsAggregator.gradient" type="range" style="width: 70%;" min="0" max="100">
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.gradientFixed=!staticSettingsAggregator.gradientFixed">{{
                  staticSettingsAggregator.gradientFixed ? '🔒' : '☣'
                }}</span>

            </li>
            <li style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title"> Density </span><span
              class="c-section-title"> {{ settingsAggregator.density }}%</span>
              <input v-model="settingsAggregator.density" type="range" style="width: 70%;" min="0" max="100">
              <span class="mutation_slider_fixer"
                    @click="settingsAggregator.densityAll=!settingsAggregator.densityAll"
              >{{ settingsAggregator.densityAll ? '∀' : '☉' }}</span>
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.densityFixed=!staticSettingsAggregator.densityFixed"
              >{{ staticSettingsAggregator.densityFixed ? '🔒' : '☣' }}</span>
            </li>
            <li style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Use line </span><span
              class="c-section-title"> {{ settingsAggregator.useLine }}%</span>
              <input v-model="settingsAggregator.useLine" type="range" style="width: 70%;" min="0" max="100">
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.useLineFixed=!staticSettingsAggregator.useLineFixed">{{
                  staticSettingsAggregator.useLineFixed ? '🔒' : '☣'
                }}</span>

            </li>
            <li v-if="settingsAggregator.useLine>0"
                style="width: 100%;  display: flex;  align-items: center; justify-content: space-between;">
              <span class="c-section-title">line Width </span><span
              class="c-section-title"> {{ settingsAggregator.lineWidth }}</span>
              <input v-model="settingsAggregator.lineWidth" type="range" style="width: 70%;" min="0" max="20">
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.lineWidthFixed=!staticSettingsAggregator.lineWidthFixed">{{
                  staticSettingsAggregator.lineWidthFixed ? '🔒' : '☣'
                }}</span>

            </li>
            <li v-if="!settingsAggregator.seamless" style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Slide X </span><span
              class="c-section-title"> <span>{{ settingsAggregator.slideMin }}px<br>{{ settingsAggregator.slide }}px</span></span>
              <DoubleRangeSlider
                :id="'slideSmple'"
                :min="settingsAggregator.slideMin"
                :max="settingsAggregator.slide"
                :max-threshold="96"
                :min-threshold="-96"
                @update:min="value => settingsAggregator.slideMin = Number(value)"
                @update:max="value => settingsAggregator.slide = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click=" settingsAggregator.slideAll=! settingsAggregator.slideAll"
              >{{ settingsAggregator.slideAll ? '∀' : '☉' }}</span>
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.slideFixed=!staticSettingsAggregator.slideFixed">{{ staticSettingsAggregator.slideFixed ? '🔒' : '☣' }}</span>
            </li>
            <li v-else style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Slide X </span><span
              class="c-section-title"> <span>{{ mutation.slideMin }}px<br>{{ mutation.slide }}px</span></span>
              <DoubleRangeSlider
                :id="'slide'"
                :min="mutation.slideMin"
                :max="mutation.slide"
                :max-threshold="96"
                :min-threshold="-96"
                @update:min="value => mutation.slideMin = Number(value)"
                @update:max="value => mutation.slide = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click="mutation.slideFixed=!mutation.slideFixed">{{ mutation.slideFixed ? '🔒' : '☣' }}</span>
            </li>
            <li v-if="!settingsAggregator.seamless" style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Slide Y </span><span
              class="c-section-title"> <span>{{ settingsAggregator.slideYMin }}px<br>{{ settingsAggregator.slideY }}px</span></span>
              <DoubleRangeSlider
                :id="'slideY'"
                :min="settingsAggregator.slideYMin"
                :max="settingsAggregator.slideY"
                :max-threshold="96"
                :min-threshold="-96"
                @update:min="value => settingsAggregator.slideYMin = Number(value)"
                @update:max="value => settingsAggregator.slideY = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click=" settingsAggregator.slideYAll=! settingsAggregator.slideYAll"
              >{{ settingsAggregator.slideYAll ? '∀' : '☉' }}</span>
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.slideYFixed=!staticSettingsAggregator.slideYFixed">{{ staticSettingsAggregator.slideYFixed ? '🔒' : '☣' }}</span>
            </li>

            <li v-else style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Slide Y </span><span
              class="c-section-title"> <span>{{ mutation.slideMinY }}px<br>{{ mutation.slideY }}px</span></span>
              <DoubleRangeSlider
                :id="'slideYSmple'"
                :min="mutation.slideMinY"
                :max="mutation.slideY"
                :max-threshold="96"
                :min-threshold="-96"
                @update:min="value => mutation.slideMinY = Number(value)"
                @update:max="value => mutation.slideY = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click="mutation.slideFixedY=!mutation.slideFixedY">{{ mutation.slideFixedY ? '🔒' : '☣' }}</span>
            </li>
            <li v-if="settingsAggregator.seamless"
                style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Rotate</span><span
              class="c-section-title"> <span>{{ mutation.rotateMin }}%<br>{{ mutation.rotate }}%</span></span>
              <DoubleRangeSlider
                :id="'rotate'"
                :min="mutation.rotateMin"
                :max="mutation.rotate"
                :max-threshold="100"
                :min-threshold="-100"
                @update:min="value => mutation.rotateMin = Number(value)"
                @update:max="value => mutation.rotate = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click="mutation.rotateFixed=!mutation.rotateFixed">{{ mutation.rotateFixed ? '🔒' : '☣' }}</span>
            </li>
            <li v-else style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Rotate</span><span
              class="c-section-title"> <span>{{ settingsAggregator.rotateMin }}%<br>{{
                settingsAggregator.rotate
              }}%</span></span>
              <DoubleRangeSlider
                :id="'simple_rotate'"
                :min="settingsAggregator.rotateMin"
                :max="settingsAggregator.rotate"
                :max-threshold="100"
                :min-threshold="-100"
                @update:min="value => settingsAggregator.rotateMin = Number(value)"
                @update:max="value => settingsAggregator.rotate = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click=" settingsAggregator.rotateAll=! settingsAggregator.rotateAll"
              >{{ settingsAggregator.rotateAll ? '∀' : '☉' }}</span>
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.rotateFixed=!staticSettingsAggregator.rotateFixed">{{
                  staticSettingsAggregator.rotateFixed ? '🔒' : '☣'
                }}</span>
            </li>
            <li v-if="!settingsAggregator.seamless"
                style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Resize</span><span
              class="c-section-title"> <span>{{ settingsAggregator.scaleMin }}%<br>{{
                settingsAggregator.scale
              }}%</span></span>
              <DoubleRangeSlider
                :id="'simple_scale'"
                :min="settingsAggregator.scaleMin"
                :max="settingsAggregator.scale"
                :max-threshold="200"
                :min-threshold="0"
                @update:min="value => settingsAggregator.scaleMin = Number(value)"
                @update:max="value => settingsAggregator.scale = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click=" settingsAggregator.scaleAll=! settingsAggregator.scaleAll"
              >{{ settingsAggregator.scaleAll ? '∀' : '☉' }}</span>
              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.scaleFixed=!staticSettingsAggregator.scaleFixed">{{
                  staticSettingsAggregator.scaleFixed ? '🔒' : '☣'
                }}</span>
            </li>
            <li v-else style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Resize</span><span
              class="c-section-title"> <span>{{ mutation.scaleMin }}%<br>{{ mutation.scale }}%</span></span>
              <DoubleRangeSlider
                :id="'scale'"
                :min="mutation.scaleMin"
                :max="mutation.scale"
                :max-threshold="200"
                :min-threshold="0"
                @update:min="value => mutation.scaleMin = Number(value)"
                @update:max="value => mutation.scale = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click="mutation.scaleFixed=!mutation.scaleFixed">{{ mutation.scaleFixed ? '🔒' : '☣' }}</span>
            </li>
            <li v-if="!settingsAggregator.seamless"
                style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Opacity</span><span
              class="c-section-title"> <span>{{ settingsAggregator.opacityMin }}%<br>{{
                settingsAggregator.opacity
              }}%</span></span>
              <DoubleRangeSlider
                :id="'simple_opacity'"
                :min="settingsAggregator.opacityMin"
                :max="settingsAggregator.opacity"
                :max-threshold="100"
                :min-threshold="0"
                @update:min="value => settingsAggregator.opacityMin = Number(value)"
                @update:max="value => settingsAggregator.opacity = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click=" settingsAggregator.opacityAll=! settingsAggregator.opacityAll"
              >{{ settingsAggregator.opacityAll ? '∀' : '☉' }}</span>

              <span class="mutation_slider_fixer"
                    @click="staticSettingsAggregator.opacityFixed=!staticSettingsAggregator.opacityFixed">{{
                  staticSettingsAggregator.opacityFixed ? '🔒' : '☣'
                }}</span>

            </li>
            <li v-if="settingsAggregator.seamless"
                style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Opacity</span><span
              class="c-section-title"> <span>{{ mutation.opacityMin }}%<br>{{ mutation.opacity }}%</span></span>
              <DoubleRangeSlider
                :id="'opacity'"
                :min="mutation.opacityMin"
                :max="mutation.opacity"
                :max-threshold="100"
                :min-threshold="0"
                @update:min="value => mutation.opacityMin = Number(value)"
                @update:max="value => mutation.opacity = Number(value)">
              </DoubleRangeSlider>
              <span class="mutation_slider_fixer"
                    @click="mutation.opacityFixed=!mutation.opacityFixed">{{
                  mutation.opacityFixed ? '🔒' : '☣'
                }}</span>
            </li>
            <li v-if="settingsAggregator.seamless"
                style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <span class="c-section-title">Mut. type</span><span
              class="c-section-title"> {{ mutation.type }}</span>
              <input v-model="mutation.type" type="range" style="width: 70%;" min="0" max="24">
              <span class="mutation_slider_fixer"
                    @click="mutation.mutationFixed=!mutation.mutationFixed">{{
                  mutation.mutationFixed ? '🔒' : '☣'
                }}</span>
            </li>
            <div
              style="width: 50%; display: flex; justify-content: center;  margin-top: 10px; margin-bottom:10px ">
                         <span
                           @click="defaultParams()"
                           style="width: 279px; display:flex; justify-content: center "
                           class=" bg_1 aggregated_field_add_button">Default</span>
            </div>

            <div v-if="settingsAggregator.seamless"
                 style="width: 50%; display: flex; justify-content: center;  margin-top: 10px; margin-bottom:10px ">
                         <span
                           @click="chaoticMutation()"
                           style="width: 279px; display:flex; justify-content: center "
                           class=" bg_4 aggregated_field_add_button">Chaotic mutation ☣</span>
            </div>
            <li
              style="width: 100%; display: flex; justify-content: center;  margin-top: 10px; margin-bottom:10px ">
              <input type="checkbox" id="chk_seamless" value="png" v-model="settingsAggregator.seamless">
              <label style="margin: 5px;" for="chk_seamless" class="c-app-btns__savejpg">
                      <span
                        :class="{'green_bg':settingsAggregator.seamless}"
                        class="btn btn-outline btn-light"
                      >
                        <span>Seamless</span>
                      </span>
              </label>
            </li>
            <li
              v-if="settingsAggregator.seamless"
              style="width: 100%;">
              <div
                style="width: 100%; display: flex; margin-top: 10px;"
                v-for="(field,index) in settingsAggregator.logicAlhoritmString"
                :key="`field${index}`"
              >
                <input
                  @keypress="inputClearValue($event,[])"
                  class="small_input bg_1"
                  type="number"
                  v-model="field.coord[0]"
                  min="1"
                  step="1"
                  :max="count[0]"
                />
                <input
                  @keypress="inputClearValue($event,[])"
                  class="small_input bg_2"
                  type="number"
                  v-model="field.coord[1]"
                  min="1"
                  :max="count[1]"
                />
                <input
                  @keypress="inputClearValue($event,[])"
                  @change="cycleControl($event,360, 0)"
                  class="small_input bg_3"
                  type="number"
                  v-model="settingsAggregator.logicAlhoritmString[index].rotation"
                  min="0"
                  max="360"
                  :step="currentKey==='Shift'?90:1"
                />
                <div
                  @click="settingsAggregator.logicAlhoritmString[index].reflection = cycleIncrement(settingsAggregator.logicAlhoritmString[index].reflection,2,1)"
                  class="seamless_item_setup bg_4">
                  <svg :v-if="settingsAggregator.logicAlhoritmString[index].reflection !== 0" width="34" height="34"
                       :viewBox="`0 0 ${(settingsAggregator.logicAlhoritmString[index].reflection===0?'0':'1000')} 1000`"
                       :transform="`rotate(${(settingsAggregator.logicAlhoritmString[index].reflection===1?'0':'90')} ,0, 0)`"
                       version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve">
<g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M8326,4783c-120-54-126-88-126-712v-564l-74,62c-42,36-156,126-254,202c-1630,1284-3716,1362-5412,204c-232-158-412-302-654-520c-244-222-276-314-150-448c40-44,70-56,136-56c92,0,102,8,458,324c636,568,1426,944,2236,1068c276,42,820,42,1094-2c840-132,1654-532,2310-1138l120-110l-696-2h-698l-58-58c-96-98-68-284,52-338c82-38,1834-34,1908,4c100,54,102,66,102,1052v920l-54,60C8500,4803,8414,4821,8326,4783z"/><path
  d="M228,3511c-24-8-62-42-86-72l-42-54V-529c0-3894,0-3914,40-3966c60-76,160-104,244-66c96,40,3950,3904,3968,3978c6,30,6,78,0,110c-18,70-3872,3936-3966,3976C314,3535,288,3535,228,3511z"/><path
  d="M9620,3509c-28-10-930-898-2006-1974C5568-511,5612-463,5650-593c20-66,3884-3932,3966-3968c84-38,184-10,244,66c40,52,40,72,40,3968v3918l-54,60C9784,3517,9696,3541,9620,3509z"/><path
  d="M4856,3033l-66-58l-6-458c-4-250-2-484,6-518c14-78,94-162,170-178c84-18,190,38,230,122c28,58,32,138,26,550l-6,482l-66,58C5054,3113,4946,3113,4856,3033z"/><path
  d="M4906,939c-118-60-128-112-122-650l6-482l66-58c76-68,186-78,262-26c90,64,102,134,102,610c0,496-12,550-130,606C5008,979,4986,979,4906,939z"/><path
  d="M4888-1207c-96-58-108-122-108-606c0-486,12-550,118-604c76-40,128-40,204,0c106,54,118,118,118,608s-12,554-118,608C5022-1159,4964-1161,4888-1207z"/><path
  d="M4856-3367l-66-58l-6-458c-4-250-2-484,6-518c14-78,94-162,170-178c84-18,190,38,230,122c28,58,32,138,26,550l-6,482l-66,58C5054-3287,4946-3287,4856-3367z"/></g></g>
</svg>
                </div>
                <input
                  @keypress="inputClearValue($event,[])"
                  class="small_input bg_5"
                  type="number"
                  v-model="field.color"
                  min="0"
                />
                <span
                  @click="settingsAggregator.logicAlhoritmString.splice(index,1)"
                  class="small_simple_button"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg"><g
                  transform="rotate(45,12,12)"><line stroke-width="5" x1="12.5" y1="2.18557e-08" x2="12.5" y2="24"
                                                     stroke="red"></line><line stroke-width="5" x1="24" y1="12.5"
                                                                               x2="-4.37114e-08" y2="12.5"
                                                                               stroke="red"></line></g></svg></span>
              </div>
              <div
                style="width: 100%; display: flex; margin-top: 10px; margin-bottom:10px ">
                         <span
                           style="width: 279px; display:flex; justify-content: center "
                           @click="pushNewLogic"
                           class="aggregated_field_add_button"> add logic to sameless blocks</span>

              </div>
              <div
                style="width: 100%; display: flex; margin-top: 10px; margin-bottom:10px ">
                         <span
                           @click="showModal('slogic')"
                           style="width: 279px; display:flex; justify-content: center "
                           class=" bg_2 aggregated_field_add_button">Save logic</span>
                <span
                  @click="showModal('mlogic');
                                getLogicDataFromDB('logic')"
                  style="width: 279px; display:flex; justify-content: center "
                  class=" bg_4 aggregated_field_add_button">Upload logic</span>

              </div>
              <modal
                name="mlogic"
                :adaptive="true"
                width="500"
                height="auto"
              >
                <div class="v--modal-inner">
                  <p class="h5 v--modal-title">Select the logic from database</p>
                  <a
                    href="#"
                    class="v--modal-close"
                    @click.prevent="hideModal('mlogic')"
                  >Close</a>
                  <table v-if="dbData.logicData.length">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Created by</th>
                      <th>Delete</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(item, index) in dbData.logicData"
                        :key="item.key">
                      <td>
                        <button
                          class="btn-small btn"
                          @click="settingsAggregator.logicAlhoritmString = item.data; hideModal('mlogic')"
                        >{{ item.name }}
                        </button>
                      </td>
                      <td>{{ item.autor }}</td>
                      <td>
                        <button @click="deleteListFromDB('logic',item.key, index)" class="btn-small btn">
                          <i class="material-icons">delete</i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </modal>
              <modal
                name="slogic"
                :adaptive="true"
                width="500"
                height="auto"
              >
                <div class="v--modal-inner">
                  <p class="h5 v--modal-title">Enter you logic name</p>
                  <input type="text" v-model="dbData.logicName" placeholder="logic name">
                  <button
                    class="btn-small btn"
                    @click="saveTheDataToDB(settingsAggregator.logicAlhoritmString,'logic',dbData.logicName);hideModal('slogic');dbData.logicName='';generateCanvas"
                  >SAVE
                  </button>
                  <a
                    href="#"
                    class="v--modal-close"
                    @click.prevent="hideModal('slogic')"
                  >Cancel</a>
                </div>
              </modal>

            </li>

          </ul>
          <ul v-if="staticSettingsAggregator.activeTabs.includes('Modules')" class="c-section-list">

            <li class="c-section-list__item c-modules">
              <Modules
                v-model="modules"
                :current-key="currentKey"
                @manualchange="manualModuleChange"
                :modificator="'Front'"
                :key="modulesRefresh"
              />
            </li>
            <div
              style="width: 100%; display: flex; margin-top: 10px; margin-bottom:10px ">
                         <span
                           @click="showModal('mCollection')"
                           style="width: 279px; display:flex; justify-content: center "
                           class=" bg_2 aggregated_field_add_button">Add modules from collection</span>
            </div>
            <modal
              name="mCollection"
              :adaptive="true"
              width="500"
              height="auto"
            >
              <div class="v--modal-inner">
                <p class="h5 v--modal-title">Set collection</p>
                <a
                  href="#"
                  class="v--modal-close"
                  @click.prevent="hideModal('mCollection')"
                >Close</a>
                <div style="display: flex;align-items: center;">
                  <label
                    class="aggregated_field_add_button"
                    :class="collections.replace?'bg_1':''"
                    style="font-size: 14px; color:black;margin: 0;"
                  ><input type="checkbox" v-model="collections.replace"> </label>
                  <span style="margin-left: 10px;">Replace existing modules</span>
                </div>
                <table v-if="dbCollections.length">
                  <thead>
                  <tr>
                    <th>Check</th>
                    <th>Name</th>
                    <th>Access</th>
                    <th>Amount</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr v-for="(item, index) in dbCollections"
                      :key="item.key">
                    <td><label
                      class="aggregated_field_add_button"
                      :class="collections.set.includes(index)?'bg_1':''"
                      style="font-size: 14px; color:black;margin: 0;"
                    ><input type="checkbox" :value="index" v-model="collections.set"> </label>
                    </td>
                    <td>
                      <button
                        class="btn-small btn"
                        @click="addToCollection(item.keys)"
                      >{{ item.name }}
                      </button>
                    </td>
                    <td>{{ item.private ? 'private' : 'public' }}</td>
                    <td>{{ item.keys.length }}</td>

                  </tr>
                  </tbody>
                </table>

                <button
                  style="margin-top: 30px;"
                  class="btn-small btn"
                  @click="addCollectionFromList(collections.set)"
                >ADD CHECKED
                </button>

                <router-link :to="`/modules`">
                  <button
                    style="margin-top: 30px;"
                    class="btn-small btn"
                  >Modules catalogue
                  </button>
                </router-link>

              </div>
            </modal>
          </ul>
          <ul v-if="staticSettingsAggregator.activeTabs.includes('Palettes')" class="c-section-list">

            <li>
              <div style="display: flex; flex-wrap: wrap;">
                <div v-for="(item, key) in settedPalette"
                     :key="'sp_'+key"
                     @click="togleSettedPalette(key)"
                     style="width: 20px; height: 20px; margin: 4px; border:2px solid white"
                     :style="'background-color: '+item.color+'; outline: 2px solid '+(item.selected?'red':'transparent')+' !important;'"
                ></div>
              </div>
            </li>

            <li class="c-section-list__item c-palettes">
              <Palettes
                v-model="palettes"
                :modificator="'Front'"
              />
            </li>
            <router-link :to="`/palettes`">
              <div
                style="width: 100%; display: flex; margin-top: 10px; margin-bottom:20px ">
                         <span
                           style="width: 279px; display:flex; justify-content: center "
                           class=" bg_2 aggregated_field_add_button">Palettes catalogue</span>
              </div>
            </router-link>
          </ul>
          <ul>
            <li>
              <div style="background-color: #E5E5E5; padding-top: 6px;" v-if="staticSettingsAggregator.activeTabs.includes('Image')">
                <div class="c-app-inputstring">
                  <input type="checkbox" id="chk_svg" value="svg" v-model="extensionType">
                  <label for="chk_svg" class="c-app-btns__savejpg">
                      <span
                        :class="{'green_bg':extensionType.includes('svg')}"
                        class="btn btn-outline btn-light"
                      >
                        <span>SVG</span>
                      </span>
                  </label>
                  <input type="checkbox" id="chk_png" value="png" v-model="extensionType">
                  <label for="chk_png" class="c-app-btns__savejpg">
                      <span
                        :class="{'green_bg':extensionType.includes('png')}"
                        class="btn btn-outline btn-light"
                      >
                        <span>PNG</span>
                      </span>
                  </label>
                  <input type="checkbox" id="chk_jpg" value="jpeg" v-model="extensionType">
                  <label for="chk_jpg" class="c-app-btns__savejpg">
                      <span
                        :class="{'green_bg':extensionType.includes('jpeg')}"
                        class="btn btn-outline btn-light"
                      >
                        <span>JPG</span>
                      </span>
                  </label>
                  <input v-if="false" type="checkbox" id="chk_eps" value="eps" v-model="extensionType">
                  <label v-if="false" for="chk_eps" class="c-app-btns__saveeps">
                      <span
                        :class="{'green_bg':extensionType.includes('eps')}"
                        class="btn btn-outline btn-light"
                      >
                        <span>EPS</span>
                      </span>
                  </label>
                </div>
                <div class="c-app-inputstring" style="justify-content:space-between;"
                     v-if="extensionType.includes('jpeg') || extensionType.includes('png')">
                  <span>Noise: </span>
                  <select
                    style="display: block;max-width: 180px;"
                    v-model="settingsAggregator.noiseMutation"
                  >
                    <option :value="component"
                            v-for="(component, index) in optionsList"
                            :key="index">{{ component }}
                    </option>
                  </select>
                  <span style="margin-left: 10px;margin-right: 10px;"> Noise power </span><input min="0" max="100"
                                                                                                 v-model="settingsAggregator.noiseOpacity"
                                                                                                 type="number">

                </div>
                <div class="c-app-inputstring" v-if="extensionType.includes('jpeg') || extensionType.includes('png')">

                  <span> Vector to raster factor: </span>
                  <input min="1" max="800" v-model="rastrMultiplier" type="number">
                  <span>%</span><span>{{ canvasHeightWeight }} </span>
                </div>
              </div>

            </li>
          </ul>
          <div class="c-app-btns" style="position: fixed; width: 417px;">
            <div class="c-app-btns__inner">
              <div class="c-app-btns__generate">
                <a
                  href=""
                  class="btn btn-dark btn-block"
                  @click.prevent="generateCanvas()"
                >GENERATE</a>
              </div>
              <div class="c-app-btns__download">
                <a
                  href=""
                  :disabled="extensionType.length === 0"
                  class="btn btn-outline btn-light"
                  @click.prevent="downloadSettedImg()"
                >
                  <svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 16L9 24L17 16" stroke="#444444" stroke-width="2"/>
                    <line x1="9" y1="4.37114e-08" x2="9" y2="24" stroke="#444444" stroke-width="2"/>
                  </svg>
                </a>
              </div>
              <div class="c-app-btns__save">
                <a
                  href=""
                  class="btn btn-outline btn-light"
                  @click.prevent="copyToBase()"
                  :disabled="saveIsPushed || !isAnyChangeForDB || !theFetchedData "
                  :class="{'green_bg':(!isAnyChangeForDB)}"
                >{{ (isAnyChangeForDB) ? (!saveIsPushed ? 'SAVE' : 'SAVING...') : 'SAVED' }}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="c-app-result">
          <div class="c-img">
            <svg
              id="theCanvas"
              ref="svgCanvas"
              xmlns="http://www.w3.org/2000/svg"
              :width="canvasScaleWidth"
              :height="canvasScaleHeight"
              :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`"
              :key="canvasWasRecreated"
            >
              <rect
                v-if="background"
                :width="canvasWidth"
                :height="canvasHeight"
                :fill="background"
                :key="canvasWasRecreated"
              />

              <g
                v-for="(component, index) in canvasComponents"
                :key="`component${index}`"
                :ref="`component${index}`"
                pointer-events="all"
                :transform="`${component.position} ${component.rotate} ${component.reflect}`"
                style="pointer-events: visibleFill;"
                @click.prevent="changeModule(index)"
                @mouseover="moduleIsHover(index)"
                @mouseout="moduleIsUnhover(index)"
                v-html="component.string"
              />
            </svg>
          </div>
        </div>
      </div>
    </main>
</template>

<script>

// sections
import Field from '../components/sections/Field.vue'
import Modules from '../components/sections/Modules.vue'
import Palettes from '../components/sections/Palettes.vue'
import Grid from '../components/sections/Grid.vue'
import Random from '../components/sections/Random.vue'
import Rotate from '../components/sections/Rotate.vue'
import HotGenerating from '../components/sections/HotGenerating.vue'
import Background from '../components/sections/Background.vue'
import DoubleRangeSlider from '../components/sections/DoubleRangeSlider.vue'
import Spaces from '../components/sections/Spaces.vue'
import Navbar from '@/components/app/Navbar'
// import Slider from '@vueform/slider'

import { parse } from 'postsvg'
import render from 'posthtml-render'
// import { readFileSync } from 'fs'
// import { convert } from 'imagemagick-convert'

export default {
  name: 'new-layout',
  components: {
    Field,
    Modules,
    Palettes,
    Grid,
    Random,
    Rotate,
    HotGenerating,
    Background,
    Spaces,
    Navbar,
    DoubleRangeSlider
  },
  data () {
    return {
      tabs: ['Field', 'Sliders', 'Modules', 'Palettes', 'Image'],
      newCount: [6, 4],
      count: localStorage.count ? JSON.parse(localStorage.count) : [6, 4],
      modules: localStorage.modules ? JSON.parse(localStorage.modules) : [],
      palettes: localStorage.palettes ? JSON.parse(localStorage.palettes) : JSON.parse('[{"colors":["#4CAF50","#8BC34A","#CDDC39","#FFEB3B","#FFC107","#FF9800","#FF5722"],"indexer":"-M_apIYSYeh3qYFVXPKE","selected":false,"favorite":false,"displayed":true},{"colors":["#455A64","#673AB7","#3F51B5","#2196F3","#03A9F4","#00BCD4","#009688"],"indexer":"-M_apIYVQ8vb06W62ItW","selected":false,"favorite":false,"displayed":true}]'),
      limited: localStorage.limited ? JSON.parse(localStorage.limited) : 0,
      fullRandom: localStorage.fullRandom ? JSON.parse(localStorage.fullRandom) : false,
      rotate: localStorage.rotate ? JSON.parse(localStorage.rotate) : true,
      hotGenerating: localStorage.hotGenerating ? JSON.parse(localStorage.hotGenerating) : false,
      grid: localStorage.grid ? JSON.parse(localStorage.grid) : 0,
      background: localStorage.background ? JSON.parse(localStorage.background) : null,
      canvasComponents: [],
      generating: false,
      randomModules: [],
      randomPalette: [],
      settedPalette: [],
      currentKey: null,
      spaces: localStorage.spaces ? JSON.parse(localStorage.spaces) : {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      },
      keyModifier: null,
      toDBinterval: null,
      hotkeyPanel: false,
      isAnyChangeForDB: false,
      prid: this.$route.query.d,
      theFetchedData: false,
      saveIsPushed: false,
      saveRandomPalettes: false,
      autosaveInterval: 15,
      canvasArchive: {},
      paletesAutoset: null,
      getTheLastCanvas: false,
      showTheShortkeys: false,
      modulesArchive: {},
      selectedPalettesArchive: {},
      palettesArchive: {},
      moduleHover: null,
      skipOneGeneration: false,
      secondKeyModifier: null,
      aggregatedField: localStorage.aggregatedField ? JSON.parse(localStorage.aggregatedField) : [],
      aggregatedFieldIsCorrupt: false,
      gridIsFocused: false,
      modulesCoordData: new Map(),
      theCanvasFormula: {},
      readyForGenerate: true,
      canvasScaleArchive: [],
      canvasWasRecreated: 0,
      rastrMultiplier: localStorage.rastrMultiplier ? JSON.parse(localStorage.rastrMultiplier) : 100,
      toggleToS: [],
      extensionType: ['svg'],
      optionsList: [
        '',
        'Multiply',
        'Lighten',
        'Darken',
        'Color Burn',
        'Linear Burn',
        'Difference',
        'Exclusion',
        'Screen',
        'Color Dodge',
        'Linear Dodge',
        'Overlay',
        'Soft Light',
        'Hard Light'
      ],
      mutationControl: false,
      settingsAggregator: {
        seamless: false,
        transparent: 0,
        gradient: 0,
        noiseMutation: '',
        noiseOpacity: 0,
        logicAlhoritmString: [{ coord: [1, 1], rotation: 0, reflection: 0, color: 0 }],
        density: 100,
        tightness: 100,
        useLine: 0,
        lineWidth: 3,
        opacity: 100,
        opacityMin: 100,
        slide: 0,
        slideMin: 0,
        slideY: 0,
        slideYMin: 0,
        rotate: 0,
        rotateMin: 0,
        scale: 100,
        scaleMin: 100
      },
      staticSettingsAggregator: {
        activeTabs: ['Field', 'Sliders', 'Modules', 'Palettes', 'Image'],
        transparentFixed: true,
        gradientFixed: true,
        densityFixed: true,
        densityAll: true,
        tightnessFixed: true,
        useLineFixed: true,
        lineWidthFixed: true,
        opacityFixed: true,
        opacityAll: true,
        slideFixed: true,
        slideYFixed: true,
        slideAll: true,
        slideYAll: true,
        rotateFixed: true,
        rotateAll: true,
        scaleFixed: true,
        scaleAll: true,
        limitedFixed: true
      },
      dbData: {
        logicName: '',
        logicData: { name: '', data: '', key: '', autor: '' },
        fieldName: '',
        fieldData: { name: '', data: '', key: '', autor: '' }
      },
      modulesRefresh: false,
      dbCollections: [],
      collections: {
        set: [],
        replace: false
      },
      doNotGenerate: true,
      firstGenetration: true,
      nodeID: 0,
      mutation: {
        slide: 0,
        slideY: 0,
        rotate: 0,
        scale: 100,
        type: 0,
        opacity: 100,
        slideMin: 0,
        slideMinY: 0,
        rotateMin: 0,
        scaleMin: 100,
        opacityMin: 100,
        slideFixed: false,
        slideFixedY: false,
        rotateFixed: false,
        scaleFixed: false,
        mutationFixed: false,
        opacityFixed: false
      },
      justPalette: false,
      defaultRefresh: false,
      skipOnGeneration: []
    }
  },
  computed: {
    canvasHeightWeight () {
      return '(' + Math.round(this.canvasWidth * this.rastrMultiplier / 100) + 'x' + Math.round(this.canvasHeight * this.rastrMultiplier / 100) + ')'
    },
    svgCanvas () {
      return this.$refs.svgCanvas
    },
    moduleSize () {
      return 96
    },
    realSpaces () {
      return {
        left: this.spaces.left,
        top: this.spaces.top,
        right: this.spaces.right,
        bottom: this.spaces.bottom
      }
    },
    gridSize () {
      return this.grid
    },
    canvasWidth () {
      return (this.count[0] * this.moduleSize) + (this.gridSize * (this.count[0] - 1)) + (this.realSpaces.left + this.realSpaces.right)
    },
    canvasHeight () {
      return (this.count[1] * this.moduleSize) + (this.gridSize * (this.count[1] - 1)) + (this.realSpaces.top + this.realSpaces.bottom)
    },
    selectedModules () {
      return this.modules.filter(module => module.selected === true)
    },
    selectedPalette () {
      return this.palettes.filter(palette => palette.selected === true)
    },
    canvasScaleWidth () {
      return this.canvasWidth
    },
    canvasScaleHeight () {
      return this.canvasHeight
    }
  },
  watch: {
    settingsAggregator: {
      deep: true,
      async handler (value) {
        localStorage.settingsAggregator = JSON.stringify(value)
        this.isAnyChangeForDB = true
        if (this.hotGenerating || this.settingsAggregator.seamless) {
          this.skipOnGeneration.push('settings')
          this.generateCanvas()
        }
        // this.defaultRefresh = !this.defaultRefresh
      }
    },
    staticSettingsAggregator: {
      deep: true,
      async handler (value) {
        localStorage.staticSettingsAggregator = JSON.stringify(value)
        //  this.defaultRefresh = !this.defaultRefresh
      }
    },
    modules: {
      deep: true,
      async handler (value) {
        // if (this.hotGenerating && this.limited === 0) {
        //   this.generateCanvas()
        // }
        const nmd = []
        value.forEach(module => {
          nmd.push({ indexer: module.indexer, selected: module.selected })
        })
        localStorage.modules = JSON.stringify(nmd)
        this.isAnyChangeForDB = true
        if (this.mutationControl === true) {
          console.log('modules')
        }
      }
    },
    palettes: {
      deep: true,
      async handler (value) {
        localStorage.palettes = JSON.stringify(value)
        if (value.filter(v => v.selected).length === 0) { this.settedPalette = [] }
        if (this.hotGenerating) {
          this.generateCanvas()
        }
        this.isAnyChangeForDB = true
        if (this.mutationControl === true) {
        }
        this.randomPalette = []
        if (value.filter(p => p.selected).length === 0) {
          value.forEach(pl => {
            this.randomPalette.push(pl.color)
          })
        } else {
          value.filter(p => p.selected).forEach(pl => {
            this.randomPalette.push(pl.color)
          })
        }
      }
    },
    mutation: {
      deep: true,
      async handler (value) {
        localStorage.mutation = JSON.stringify(value)
        this.isAnyChangeForDB = true
        this.canvasComponents.forEach((component, index) => {
          this.$nextTick(() => {
            this.mutate(index)
          })
        })
      }
    },
    settedPalette: {
      deep: true,
      async handler (value) {
        this.randomPalette = []
        if (value.filter(p => p.selected).length === 0) {
          value.forEach(pl => {
            this.randomPalette.push(pl.color)
          })
        } else {
          value.filter(p => p.selected).forEach(pl => {
            this.randomPalette.push(pl.color)
          })
        }
      }
    },
    rastrMultiplier (value) {
      localStorage.rastrMultiplier = JSON.stringify(value)
      this.isAnyChangeForDB = true
    },
    extensionType (value) {
      localStorage.extensionType = JSON.stringify(value)
      this.isAnyChangeForDB = true
    },
    aggregatedField: {
      deep: true,
      async handler (value) {
        localStorage.aggregatedField = JSON.stringify(value)
        this.isAnyChangeForDB = true
        if (this.hotGenerating) {
          this.generateCanvas()
        }
      }
    },
    limited (value) {
      localStorage.limited = JSON.stringify(value)
      if (this.hotGenerating && !this.holdGenerating) {
        this.generateCanvas()
      } else {
        this.selectRandomModules()
      }
    },
    fullRandom (value) {
      localStorage.fullRandom = JSON.stringify(value)
      if (this.hotGenerating) {
        this.generateCanvas()
      }
      this.isAnyChangeForDB = true
    },
    rotate (value) {
      localStorage.rotate = JSON.stringify(value)
      if (this.hotGenerating) {
        this.generateCanvas()
      }
      this.isAnyChangeForDB = true
    },
    hotGenerating (value) {
      localStorage.hotGenerating = JSON.stringify(value)
      this.isAnyChangeForDB = true
    },
    count (value) {
      localStorage.count = JSON.stringify(value)
      this.isAnyChangeForDB = true
    },
    newCount () {
      if (this.hotGenerating) {
        this.count = this.newCount
        this.generateCanvas()
      }
    },
    grid (value) {
      localStorage.grid = JSON.stringify(value)
      if (this.hotGenerating) {
        this.generateCanvas()
      } else {
        this.$nextTick(() => {
          this.canvasComponents.forEach((component, index) => {
            if (component.factor > 1) {
              this.resizeTheCanvasElement(index)
            }
            this.setComponentPosition(index, 1)
          })
        })
      }
      this.isAnyChangeForDB = true
    },
    spaces (value) {
      localStorage.spaces = JSON.stringify(value)
      if (this.hotGenerating) {
        this.generateCanvas()
      } else {
        this.$nextTick(() => {
          this.canvasComponents.forEach((component, index) => {
            this.setComponentPosition(index, 1)
          })
        })
      }
      this.isAnyChangeForDB = true
    },
    background (value) {
      localStorage.background = JSON.stringify(value)
      this.isAnyChangeForDB = true
    },
    selectedPalette () {
      this.setRandomPalette()
      if (!this.settingsAggregator.seamless) {
        this.$nextTick(() => {
          this.canvasComponents.forEach((component, index) => {
            this.setComponentColor(index)
          })
        })
      }
    },
    selectedModules () {
      this.setRandomModules()
    }
  },
  async created () {
    await this.$store.dispatch('enterTheProject', this.prid)
    await this.getDataFromLocalStorage()
    this.generateCanvas()
  },
  async mounted () {
    this.getAllCollection()
    clearInterval(this.interval)
    this.toDBinterval = setInterval(() => {
      if (this.theFetchedData) {
        this.checkToBD()
      }
    }, this.autosaveInterval * 1000)
    if (!Object.keys(this.$store.getters.info).lenght) {
      await this.$store.dispatch('fetchInfo', this.prid)
    }
    this.$store.commit('setTheProject', this.prid)
    window.addEventListener('keydown', this.keyListener, false)
    window.addEventListener('keyup', e => {
      this.currentKey = null
      if (['Shift', 'Control', 'Alt', 'Meta'].includes(e.key)) {
        if (!this.secondKeyModifier) {
          this.keyModifier = null
        }
        if (this.keyModifier !== this.secondKeyModifier) {
          this.secondKeyModifier = null
        } else { this.keyModifier = null }
      }
    })
  },
  methods: {
    keyListener (e) {
      if (e.keyCode === 71 || e.keyCode === 32) {
        e.preventDefault()
        this.generateCanvas()
      }
      if (e.keyCode === 77) {
        e.preventDefault()
        if (this.settingsAggregator.seamless) {
          this.chaoticMutation()
        }
      }
      if (this.keyModifier === 'Ctrl' && e.keyCode > 47 && e.keyCode < 58) {
        e.preventDefault()
        this.limited = e.key
        this.staticSettingsAggregator.limitedFixed = true
      }
      if (e.keyCode === 68) {
        e.preventDefault()
        if (this.currentKey === 'Ctrl') {
          this.modules.forEach(module => {
            module.selected = false
          })
        } else {
          this.downloadSettedImg()
        }
      }
      if (this.keyModifier === 'Ctrl' && e.keyCode === 90) {
        e.preventDefault()
        this.getLastCanvas()
      }
      if (this.keyModifier === 'Ctrl' && this.secondKeyModifier === 'Shift' && e.keyCode === 90) {
        e.preventDefault()
        this.getNextCanvas()
      }
      if (this.keyModifier === 'Shift' && e.keyCode === 80) {
        e.preventDefault()
        this.justPalette = true
        this.useThisAutosettedPalette()
      }
      if (this.keyModifier === 'Ctrl' && e.keyCode === 80) {
        e.preventDefault()
        e.stopPropagation()
        this.justPalette = true
        this.unuseThisAutosettedPalette()
      }
      this.currentKey = e.keyCode
      if (e.key === 'h') {
        this.hotkeyPanel = !this.hotkeyPanel
      }
      if (e.ctrlKey || e.keyCode === 224 || e.metaKey || e.keyCode === 91 || e.keyCode === 93) {
        if (['Shift', 'Alt'].includes(this.keyModifier)) {
          this.secondKeyModifier = 'Ctrl'
        } else {
          this.keyModifier = 'Ctrl'
        }
        this.currentKey = 'Ctrl'
      }
      if (e.altKey) {
        if (['Shift', 'Ctrl'].includes(this.keyModifier)) {
          this.secondKeyModifier = 'Alt'
        } else {
          this.keyModifier = 'Alt'
        }
        this.currentKey = 'Alt'
      }
      if (e.shiftKey) {
        if (['Ctrl', 'Alt'].includes(this.keyModifier)) {
          this.secondKeyModifier = 'Shift'
        } else {
          this.keyModifier = 'Shift'
        }
        this.currentKey = 'Shift'
      }
      if (e.keyCode === 83) {
        e.preventDefault()
        if (this.toggleToS.length === 0 && this.moduleHover !== null) {
          const index = this.moduleHover
          this.modules.forEach(module => {
            if (module.selected === true) {
              this.toggleToS.push(Number(module.indexer))
            }
          })
          this.selectSingleModule(index)
        } else {
          this.modules.forEach(module => {
            if (this.toggleToS.includes(module.indexer)) {
              module.selected = true
            } else module.selected = false
          })
          this.toggleToS = []
        }
      }
      if (this.moduleHover !== null) {
        const index = this.moduleHover
        if (e.keyCode === 82) {
          let increment = 90
          if (this.keyModifier === 'Shift') increment = 1
          if (this.keyModifier === 'Alt') increment = -1
          e.preventDefault()
          let rot = 90
          if (this.settingsAggregator.seamless) {
            this.canvasComponents.forEach((component, index) => {
              if (component.rotate.match(/rotate\(([\d]{1,3})/)) { rot = Number(component.rotate.match(/rotate\(([\d]{1,3})/)[1]) + increment }
              if (rot > 359)rot = rot - 360
              if (rot < 0) rot = rot + 360
              this.setComponentSpecificRotation(index, rot)
            })
            this.settingsAggregator.logicAlhoritmString.forEach(logic => {
              logic.rotation = Number(logic.rotation) + increment
              if (logic.rotation > 359) logic.rotation = Number(logic.rotation) - 360
              if (logic.rotation < 0) logic.rotation = Number(logic.rotation) + 360
            })
          } else {
            if (this.canvasComponents[index].rotate.match(/rotate\(([\d]{1,3})/)) { rot = Number(this.canvasComponents[index].rotate.match(/rotate\(([\d]{1,3})/)[1]) + increment }
            if (rot > 359)rot = rot - 360
            if (rot < 0) rot = rot + 360
            this.setComponentSpecificRotation(index, rot)
          }
        } else if (e.keyCode === 84) {
          e.preventDefault()
          if (this.settingsAggregator.seamless) {
            this.canvasComponents.forEach((component, index) => {
              let mirror = 3
              if (component.reflect.length === 0) {
                mirror = 2
              } else {
                const match = component.reflect.match(/scale\(([-]{0,1}\d), ([-]{0,1}\d)/)
                if (match[1] > match[2]) {
                  mirror = 1
                } else {
                  mirror = 3
                }
              }
              this.setComponentSpecificReflection(index, mirror)
            })
            this.settingsAggregator.logicAlhoritmString.forEach(logic => {
              logic.reflection = this.cycleIncrement(logic.reflection, 2, 1)
            })
          } else {
            let mirror = 3
            if (this.canvasComponents[index].reflect.length === 0) {
              mirror = 2
            } else {
              const match = this.canvasComponents[index].reflect.match(/scale\(([-]{0,1}\d), ([-]{0,1}\d)/)
              if (match[1] > match[2]) {
                mirror = 1
              } else {
                mirror = 3
              }
            }
            this.setComponentSpecificReflection(index, mirror)
          }
        }
        if (e.keyCode === 67) {
          e.preventDefault()
          if (this.settingsAggregator.seamless) {
            this.generateCanvas()
          } else {
            this.setComponentColor(index)
          }
        }
      }
      if (e.keyCode === 80 && this.keyModifier !== 'Ctrl' && this.keyModifier !== 'Shift') {
        if (this.hotGenerating === true) this.skipOneGeneration = true
        this.paletesAutoset = Math.floor(Math.random() * this.palettes.length)
        if (this.palettes.filter(palette => palette.selected === true).length > 0) this.palettes.filter(palette => palette.selected === true)[0].selected = false
        this.palettes[this.paletesAutoset].selected = true
        if (this.settingsAggregator.seamless) {
          this.skipOneGeneration = false
          if (!this.hotGenerating) {
            this.generateCanvas()
          }
        }
        this.saveRandomPalettes = true
      }
    },
    generateCanvas () {
      if (this.generating || this.doNotGenerate) {
        return
      }
      if (this.skipOneGeneration) {
        this.skipOneGeneration = false
        return
      }
      this.generating = true
      this.count = this.newCount
      this.canvasComponents = []
      if (!this.skipOnGeneration.includes('settings')) {
        this.chaoticSimpleMutation()
      }
      this.selectRandomModules()
      this.setRandomModules()
      if (this.palettes.filter(r => r.selected).length === 0) { this.setRandomPalette() }
      if (this.randomModules.length) {
        const generatedFields = this.generatedFieldsForCanvas(this.aggregatedField)
        const clearField = generatedFields[0]
        const aggrField = generatedFields[1]
        if (this.settingsAggregator.seamless === true) {
          this.generateCanvasFromSeamless(aggrField, clearField)
        } else {
          this.generateCanvasRandom(aggrField, clearField)
        }
        this.saveLastCanvas()
        this.skipOnGeneration = []
      } else {
        this.generating = false
      }
    },
    mutate (index) {
      const component = this.canvasComponents[index]
      const mx = Number(this.count[0] - 1)
      const my = Number(this.count[1] - 1)
      const x = component.x
      const y = component.y
      let power = 0
      if (Number(this.mutation.type) === 1) {
        power = (x + y) / (my + mx)
      } else if (Number(this.mutation.type) === 2) {
        power = (x * y) / (my * mx)
      } else if (Number(this.mutation.type) === 0) {
        power = 1
      } else if (Number(this.mutation.type) === 3) {
        const max = mx * my / 4
        const ny = Math.sqrt(Math.pow((my / 2 - y), 2))
        const nx = Math.sqrt(Math.pow((mx / 2 - x), 2))
        power = Math.sqrt(ny * nx / max)
      } else if (Number(this.mutation.type) === 4) {
        const max = mx * my / 4
        const ny = Math.sqrt(Math.pow((my / 2 - y), 2))
        const nx = Math.sqrt(Math.pow((mx / 2 - x), 2))
        power = (ny * nx / max)
      } else if (Number(this.mutation.type) === 5) {
        const max = Math.pow((mx / 2), 2) * Math.pow((my / 2), 2)
        const ny = Math.pow((my / 2 - y), 2)
        const nx = Math.pow((mx / 2 - x), 2)
        power = 1 - Math.sqrt((ny * nx) / max)
      } else if (Number(this.mutation.type) === 6) {
        const max = (mx + my) / 2
        const ny = Math.sqrt(Math.pow(my / 2 - y, 2))
        const nx = Math.sqrt(Math.pow(mx / 2 - x, 2))
        power = Math.sqrt((ny + nx) / max)
      } else if (Number(this.mutation.type) === 7) {
        const max = (mx > my ? mx / 2 : my / 2)
        const ny = Math.sqrt(Math.pow(my / 2 - y, 2))
        const nx = Math.sqrt(Math.pow(mx / 2 - x, 2))
        power = (Math.sqrt(Math.pow(ny - nx, 2)) / max)
      } else if (Number(this.mutation.type) === 8) {
        power = x / mx
      } else if (Number(this.mutation.type) === 9) {
        power = y / my
      } else if (Number(this.mutation.type) === 10) {
        power = Math.sqrt(Math.pow(mx / 2 - x, 2)) / (mx / 2)
      } else if (Number(this.mutation.type) === 11) {
        power = Math.sqrt(Math.pow(my / 2 - y, 2)) / (my / 2)
      } else if (Number(this.mutation.type) === 12) {
        const max = (mx > my ? mx : my)
        power = Math.sqrt(Math.pow(x - y, 2)) / max
      } else if (Number(this.mutation.type) === 13) {
        const max = (mx > my ? mx : my)
        power = 1 - (Math.sqrt(Math.pow(x - y, 2)) / max)
      } else if (Number(this.mutation.type) === 14) {
        const max = (mx + my) / 2
        power = Math.sqrt(Math.pow(y + x - max, 2)) / max
      } else if (Number(this.mutation.type) === 15) {
        const max = (mx + my) / 2
        power = 1 - Math.sqrt(Math.pow(y + x - max, 2)) / max
      } else if (Number(this.mutation.type) === 16) {
        const max = (mx > my ? mx / 2 : my / 2)
        const ny = Math.sqrt(Math.pow(my / 2 - y, 2))
        const nx = Math.sqrt(Math.pow(mx / 2 - x, 2))
        power = 1 - (Math.sqrt(Math.pow(ny - nx, 2)) / max)
      } else if (Number(this.mutation.type) === 17) {
        const max = Math.sqrt((mx / 2) ** 2 + (my / 2) ** 2)
        const ny = (Math.pow(my / 2 - y, 2))
        const nx = (Math.pow(mx / 2 - x, 2))
        power = (Math.sqrt((ny + nx)) / max) ** 2
      } else if (Number(this.mutation.type) === 18) {
        const max = Math.sqrt((mx / 2) ** 2 + (my / 2) ** 2)
        const ny = (Math.pow(my / 2 - y, 2))
        const nx = (Math.pow(mx / 2 - x, 2))
        power = 1 - (Math.sqrt((ny + nx)) / max) ** 2
      } else if (Number(this.mutation.type) === 19) {
        power = (y % 2 > 0 ? mx - x : x) / mx
      } else if (Number(this.mutation.type) === 20) {
        power = (x % 2 > 0 ? my - y : y) / my
      } else if (Number(this.mutation.type) === 21) {
        power = (x * my + y) / (mx * my + my)
      } else if (Number(this.mutation.type) === 22) {
        power = (mx * y + x) / (mx * my + mx)
      } else if (Number(this.mutation.type) === 23) {
        power = (x % 2 > 0 ? (my * x + y) / (mx * my + my) : (my * x + my - y) / (mx * my + my))
      } else if (Number(this.mutation.type) === 24) {
        power = (y % 2 > 0 ? (mx * y + x) / (mx * my + mx) : (y * mx + mx - x) / (mx * my + mx))
      }
      const slide = [Number(this.mutation.slideMin), Number(this.mutation.slide)]
      const slideY = [Number(this.mutation.slideMinY), Number(this.mutation.slideY)]
      const scale = [Number(this.mutation.scaleMin), Number(this.mutation.scale)]
      const rotate = [Number(this.mutation.rotateMin), Number(this.mutation.rotate)]
      const opacity = [Number(this.mutation.opacityMin), Number(this.mutation.opacity)]
      let transform = []

      if (rotate) {
        this.canvasComponents[index].rotate = 'rotate(' + (rotate[0] * 3.6 + (power * (rotate[1] - rotate[0]) * 3.6)) + ', 48,48)'
      }
      Promise.resolve(parse(component.string))
        .then(svgTree => {
          svgTree.each((node) => {
            if (['rect', 'circle', 'ellipse', 'line', 'polyline', 'polygon', 'path'].includes(node.tag) && node.attrs.id !== 'bgnd') {
              transform = []
              if (slide || slideY) {
                transform.push('translate(' + (slide[0] + power * (slide[1] - slide[0])) + ', ' + (slideY[0] + power * (slideY[1] - slideY[0])) + ')')
              }
              if (rotate) {
                // transform.push('rotate(' + (rotate[0] * 3.6 + (power * (rotate[1] - rotate[0]) * 3.6)) + ', 48,48)')
              }
              if (scale) {
                transform.push('scale(' + (scale[0] + power * (scale[1] - scale[0])) / 100 + ')')
              }
              node.attrs.transform = transform.join(' ')
              if (opacity) {
                node.attrs['fill-opacity'] = opacity[1] / 100 - (power * (opacity[1] - opacity[0])) / 100
              }
            }
          })
          return svgTree
        })
        .then(svgTree => render(svgTree.root))
        .then(svgHtml => {
          component.string = svgHtml
          //   this.setComponentPosition(index, 1)
        })
    },
    chaoticMutation () {
      if (!this.mutation.slideFixed) this.mutation.slide = Math.floor(Math.random() * 96)
      if (!this.mutation.slideFixedY) this.mutation.slideY = Math.floor(Math.random() * 96)
      if (!this.mutation.rotateFixed) this.mutation.rotate = Math.floor(Math.random() * 100)
      if (!this.mutation.scaleFixed) this.mutation.scale = 100 + Math.floor(Math.random() * 100)
      if (!this.mutation.mutationFixed) this.mutation.type = Math.floor(Math.random() * 25)
      if (!this.mutation.opacityFixed) this.mutation.opacity = 50 + Math.floor(Math.random() * 50)
      if (!this.mutation.slideFixed) this.mutation.slideMin = -96 + Math.floor(Math.random() * 96)
      if (!this.mutation.slideFixedY) this.mutation.slideMinY = -96 + Math.floor(Math.random() * 96)
      if (!this.mutation.rotateFixed) this.mutation.rotateMin = -100 + Math.floor(Math.random() * 100)
      if (!this.mutation.scaleFixed) this.mutation.scaleMin = Math.floor(Math.random() * 100)
      if (!this.mutation.opacityFixed) this.mutation.opacityMin = Math.floor(Math.random() * 50)
      this.canvasComponents.forEach((component, index) => {
        this.$nextTick(() => {
          this.mutate(index)
        })
      })
      this.defaultRefresh = !this.defaultRefresh
    },
    chaoticSimpleMutation () {
      if (!this.staticSettingsAggregator.transparentFixed) this.settingsAggregator.transparent = (!this.settingsAggregator.seamless ? Math.floor(Math.random() * 100) : (Math.random() > 0.5 ? 100 : 0))
      if (!this.staticSettingsAggregator.gradientFixed) this.settingsAggregator.gradient = Math.floor(Math.random() * 100)
      if (!this.staticSettingsAggregator.densityFixed) this.settingsAggregator.density = Math.floor(Math.random() * 100)
      if (!this.staticSettingsAggregator.tightnessFixed) this.settingsAggregator.tightness = Math.floor(Math.random() * 100)
      if (!this.staticSettingsAggregator.useLineFixed) this.settingsAggregator.useLine = Math.floor(Math.random() * 100)
      if (!this.staticSettingsAggregator.lineWidthFixed) this.settingsAggregator.lineWidth = 1 + Math.floor(Math.random() * 19)
      if (!this.staticSettingsAggregator.opacityFixed) this.settingsAggregator.opacity = 50 + Math.floor(Math.random() * 50)
      if (!this.staticSettingsAggregator.opacityFixed) this.settingsAggregator.opacityMin = Math.floor(Math.random() * 50)
      if (!this.staticSettingsAggregator.slideFixed) this.settingsAggregator.slide = Math.floor(Math.random() * 96)
      if (!this.staticSettingsAggregator.slideYFixed) this.settingsAggregator.slideY = Math.floor(Math.random() * 96)
      if (!this.staticSettingsAggregator.slideFixed) this.settingsAggregator.slideMin = Math.floor(Math.random() * -96)
      if (!this.staticSettingsAggregator.slideYFixed) this.settingsAggregator.slideYMin = Math.floor(Math.random() * -96)
      if (!this.staticSettingsAggregator.rotateFixed) this.settingsAggregator.rotate = Math.floor(Math.random() * 50)
      if (!this.staticSettingsAggregator.rotateFixed) this.settingsAggregator.rotateMin = -50 + Math.floor(Math.random() * 50)
      if (!this.staticSettingsAggregator.scaleFixed) this.settingsAggregator.scale = 100 + Math.floor(Math.random() * 50)
      if (!this.staticSettingsAggregator.scaleFixed) this.settingsAggregator.scaleMin = 50 + Math.floor(Math.random() * 50)
      if (!this.staticSettingsAggregator.limitedFixed) this.limited = 1 + Math.floor(Math.random() * 8)
      this.defaultRefresh = !this.defaultRefresh
    },
    setComponentColor (index) {
      const colors = {
        none: 'none'
      }
      const constRandom = Math.random()
      const colorsWatcher = ['none']
      const component = this.canvasComponents[index]
      const palletesColorsAmount = this.randomPalette.length
      const gradient = []
      let gradientCounter = 0
      if (!component.origin) {
        component.origin = component.string
      }
      if (this.settingsAggregator.rotateAll) {
        this.canvasComponents[index].rotate = 'rotate(' + (Math.floor(this.settingsAggregator.rotateMin * 3.6) + Math.floor((this.settingsAggregator.rotate - this.settingsAggregator.rotateMin) * Math.random() * 3.6)) + ', 48,48)'
      }
      Promise.resolve(parse(component.origin))
        .then(svgTree => {
          const nodeId = this.nodeID
          this.nodeID++
          let useLine = this.settingsAggregator.useLine >= Math.random() * 100
          svgTree.each((node) => {
            if (['rect', 'circle', 'ellipse', 'line', 'polyline', 'polygon', 'path'].includes(node.tag)) {
              let skip = false
              let opacity = false
              let scale = false
              let slide = false
              let rotate = false
              if (node.attrs.fill !== 'none') {
                // node.attrs['clip-path'] = 'url(#thecrop)'
                if (!this.settingsAggregator.densityAll) {
                  skip = Math.random() > this.settingsAggregator.density / 100
                }
                opacity = (this.settingsAggregator.opacity !== 100 || this.settingsAggregator.opacityMin !== 100)
                slide = (this.settingsAggregator.slide !== 0 || this.settingsAggregator.slideY !== 0 || this.settingsAggregator.slideMin !== 0 || this.settingsAggregator.slideYMin !== 0)
                scale = (this.settingsAggregator.scale !== 100 || this.settingsAggregator.scaleMin !== 100)
                rotate = (this.settingsAggregator.rotate !== 0 || this.settingsAggregator.rotateMin !== 0)
              }
              if (slide || rotate || scale) {
                const transform = []
                if (slide) {
                  transform.push('translate(' +
                      ((this.settingsAggregator.slideMin) +
                      Math.floor((this.settingsAggregator.slideAll ? constRandom : Math.random()) * (this.settingsAggregator.slide - this.settingsAggregator.slideMin))) + ', ' +
                      ((this.settingsAggregator.slideYMin) +
                      Math.floor((this.settingsAggregator.slideYAll ? constRandom : Math.random()) * (this.settingsAggregator.slideY - this.settingsAggregator.slideYMin))) + ')')
                }
                if (rotate) {
                  if (!this.settingsAggregator.rotateAll) {
                    transform.push('rotate(' + (Math.floor(this.settingsAggregator.rotateMin * 3.6) + Math.floor((this.settingsAggregator.rotate - this.settingsAggregator.rotateMin) * Math.random() * 3.6)) + ', 48,48)')
                  }
                  // node.attrs.style = 'transform-origin: center;transform-box: fill-box;'
                  // node.attrs.style = 'transform-origin: center;'
                }
                if (scale) {
                  transform.push('scale(' + ((this.settingsAggregator.scaleMin + (Math.floor((this.settingsAggregator.scaleAll ? constRandom : Math.random()) * (this.settingsAggregator.scale - this.settingsAggregator.scaleMin)))) / 100) + ')')
                }
                node.attrs.transform = transform.join(' ')
              }

              if (opacity) {
                node.attrs['fill-opacity'] = String((this.settingsAggregator.opacityMin + Math.floor((this.settingsAggregator.opacityAll ? constRandom : Math.random()) * (this.settingsAggregator.opacity - this.settingsAggregator.opacityMin))) / 100)
              }
              if (node.attrs.class) {
                node.attrs.fill = node.attrs.class
              } else node.attrs.class = node.attrs.fill
              if (skip) {
                node.attrs.fill = 'none'
              } else {
                if (this.fullRandom) {
                  if (node.attrs.fill !== 'none') {
                    if (this.settingsAggregator.gradient >= Math.random() * 100) {
                      node.attrs.fill = 'url(#gradient_' + nodeId + '_' + gradientCounter + ')'
                      const col1 = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                      let col2
                      if (this.randomPalette.length > 1) {
                        col2 = this.randomPalette.filter(m => m !== col1)[Math.floor(Math.random() * (this.randomPalette.length - 1))]
                      } else { col2 = '#000000' }
                      gradient.push({
                        tag: 'linearGradient',
                        attrs: { id: 'gradient_' + nodeId + '_' + gradientCounter },
                        content: [
                          { tag: 'stop', attrs: { 'stop-color': col1, offset: 0 } },
                          { tag: 'stop', attrs: { 'stop-color': col2, offset: 1 } }
                        ]
                      })
                      gradientCounter++
                    } else {
                      node.attrs.fill = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                    }
                  }
                } else {
                  if (node.attrs && node.attrs.fill) {
                    if (!(node.attrs.fill in colors)) {
                      let trycolor = 'none'
                      let trycolor2 = 'none'
                      while (colorsWatcher.includes(trycolor)) {
                        trycolor = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                        if (this.randomPalette.length > 1) {
                          trycolor2 = this.randomPalette.filter(m => m !== trycolor)[Math.floor(Math.random() * (this.randomPalette.length - 1))]
                        }
                        if (colorsWatcher.length > (palletesColorsAmount)) break
                      }
                      if (this.settingsAggregator.gradient >= Math.random() * 100) {
                        colors[node.attrs.fill] = 'url(#gradient_' + nodeId + '_' + gradientCounter + ')'
                        gradient.push({
                          tag: 'linearGradient',
                          attrs: { id: 'gradient_' + nodeId + '_' + gradientCounter },
                          content: [
                            { tag: 'stop', attrs: { 'stop-color': trycolor, offset: 0 } },
                            { tag: 'stop', attrs: { 'stop-color': trycolor2, offset: 1 } }
                          ]
                        })
                        gradientCounter++
                      } else {
                        colors[node.attrs.fill] = trycolor
                      }
                      colorsWatcher.push(trycolor)
                    }

                    node.attrs.fill = colors[node.attrs.fill]
                  } else if (node.attrs && node.attrs.class) {
                    if (!(node.attrs.class in colors)) {
                      colors[node.attrs.class] = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                    }

                    node.attrs.fill = colors[node.attrs.class]
                  } else {
                    if (!('emptyElement' in colors)) {
                      colors.emptyElement = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                    }

                    node.attrs.fill = colors.emptyElement
                  }
                }
                if (node.attrs.fill === 'none') {
                  node.attrs.class = 'fill_none'
                  node.attrs.id = 'bgnd_' + nodeId
                  if (this.settingsAggregator.transparent <= Math.random() * 100) {
                    if (this.settingsAggregator.gradient >= Math.random() * 100) {
                      node.attrs.fill = 'url(#gradient_' + 'bgnd_' + nodeId + ')'
                      const col1 = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                      let col2
                      if (this.randomPalette.length > 1) {
                        col2 = this.randomPalette.filter(m => m !== col1)[Math.floor(Math.random() * (this.randomPalette.length - 1))]
                      } else { col2 = '#000000' }
                      gradient.push({
                        tag: 'linearGradient',
                        attrs: { id: 'gradient_' + 'bgnd_' + nodeId },
                        content: [
                          { tag: 'stop', attrs: { 'stop-color': col1, offset: 0 } },
                          { tag: 'stop', attrs: { 'stop-color': col2, offset: 1 } }
                        ]
                      })
                      gradientCounter++
                    } else {
                      node.attrs.fill = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                    }
                  }
                }
              }

              if (this.fullRandom) useLine = this.settingsAggregator.useLine >= Math.random() * 100
              if (useLine) {
                node.attrs.stroke = node.attrs.fill
                node.attrs['stroke-width'] = this.settingsAggregator.lineWidth + 'px'
                node.attrs['stroke-linejoin'] = 'round'
                node.attrs.fill = 'none'
              }
            }
          })
          // TODO: line width has cutting with viewBox (fixible) and crop-box (unfixible)
          if (useLine) {
            const lineHalfWidth = Math.round(this.settingsAggregator.lineWidth / 2)
            const newView = svgTree[0].attrs.viewBox.split(' ').map(Number)
            svgTree[0].attrs.viewBox =
              (newView[0] - lineHalfWidth) + ' ' +
              (newView[1] - lineHalfWidth) + ' ' +
              (newView[2] + 2 * lineHalfWidth) + ' ' +
              (newView[3] + 2 * lineHalfWidth)
          }

          svgTree[0].content = [{
            tag: 'g',
            content: svgTree[0].content,
            style: !useLine ? 'clip-path: url(#thecrop' + nodeId + '); -webkit-clip-path: url(#thecrop' + nodeId + ');' : '',
            attrs: { 'clip-path': (!useLine ? 'url(#thecrop' + nodeId + ')' : '') }
          }]
          svgTree[0].attrs.id = 'module_item' + nodeId
          // svgTree[0].content.push({
          //   tag: 'style',
          //   attrs: { type: 'text/css' },
          //   content: '#' + 'module_item' + nodeId + ' *{transform-origin: center;transform-box: fill-box;}'
          // })
          svgTree[0].content.push({
            tag: 'defs',
            content: gradient
          })
          svgTree[0].content.push({
            tag: 'clipPath',
            attrs: {
              id: 'thecrop' + nodeId
              //   clipPathUnits: 'objectBoundingBox'
            },
            content: ({ tag: 'use', attrs: { 'xlink:href': '#bgnd_' + nodeId, style: 'overflow:visible;' } })
          })
          return svgTree
        })
        .then(svgTree => render(svgTree.root))
        .then(svgHtml => {
          component.string = svgHtml
          this.setComponentPosition(index, 1)
        })
    },
    setComponentColorSeamless (string) {
      this.setRandomPalette()
      this.randomPalette = []
      if (this.settedPalette.filter(p => p.selected).length === 0) {
        this.settedPalette.forEach(pl => {
          this.randomPalette.push(pl.color)
        })
      } else {
        this.settedPalette.filter(p => p.selected).forEach(pl => {
          this.randomPalette.push(pl.color)
        })
      }
      let useLine = this.settingsAggregator.useLine >= Math.random() * 100
      const colors = {
        none: 'none'
      }
      const palletesColorsAmount = this.randomPalette.length
      const colorsWatcher = ['none']
      const svgTree = parse(string)
      const gradient = []
      let gradientCounter = 0
      svgTree.each((node) => {
        if (['rect', 'circle', 'ellipse', 'line', 'polyline', 'polygon', 'path'].includes(node.tag)) {
          let skip = false
          if (node.attrs.fill !== 'none') {
            if (!this.settingsAggregator.densityAll) {
              skip = Math.random() > this.settingsAggregator.density / 100
            }
          }
          if (skip) {
            node.attrs.fill = 'none'
          } else {
            if (this.fullRandom) {
              if (node.attrs.fill !== 'none') {
                if (this.settingsAggregator.gradient >= Math.random() * 100) {
                  node.attrs.fill = 'url(#gradient_' + gradientCounter + ')'
                  gradient.push({
                    tag: 'linearGradient',
                    attrs: { id: 'gradient_' + gradientCounter },
                    content: [
                      { tag: 'stop', attrs: { 'stop-color': this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)], offset: 0 } },
                      { tag: 'stop', attrs: { 'stop-color': this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)], offset: 1 } }
                    ]
                  })
                  gradientCounter++
                } else {
                  node.attrs.fill = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                }
              }
            } else {
              if (node.attrs && node.attrs.fill) {
                if (!(node.attrs.fill in colors)) {
                  let trycolor = 'none'
                  let trycolor2 = 'none'
                  while (colorsWatcher.includes(trycolor)) {
                    trycolor = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                    if (this.randomPalette.length > 1) {
                      trycolor2 = this.randomPalette.filter(m => m !== trycolor)[Math.floor(Math.random() * (this.randomPalette.length - 1))]
                    }
                    if (colorsWatcher.length > (palletesColorsAmount)) break
                  }
                  if (this.settingsAggregator.gradient >= Math.random() * 100) {
                    colors[node.attrs.fill] = 'url(#gradient_' + gradientCounter + ')'
                    gradient.push({
                      tag: 'linearGradient',
                      attrs: { id: 'gradient_' + gradientCounter },
                      content: [
                        { tag: 'stop', attrs: { 'stop-color': trycolor, offset: 0 } },
                        { tag: 'stop', attrs: { 'stop-color': trycolor2, offset: 1 } }
                      ]
                    })
                    gradientCounter++
                  } else {
                    colors[node.attrs.fill] = trycolor
                  }
                  colorsWatcher.push(trycolor)
                }
                node.attrs.fill = colors[node.attrs.fill]
              } else if (node.attrs && node.attrs.class) {
                if (!(node.attrs.class in colors)) {
                  colors[node.attrs.class] = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                }

                node.attrs.fill = colors[node.attrs.class]
              } else {
                if (!('emptyElement' in colors)) {
                  colors.emptyElement = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                }

                node.attrs.fill = colors.emptyElement
              }
            }
            if (node.attrs.fill === 'none') {
              node.attrs.class = 'fill_none'
              node.attrs.id = 'bgnd'
              if (this.settingsAggregator.transparent <= Math.random() * 100) {
                if (this.settingsAggregator.gradient >= Math.random() * 100) {
                  const randomAttr = Math.random()
                  node.attrs.fill = 'url(#gradient_' + randomAttr + gradientCounter + ')'
                  const col1 = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                  let col2
                  if (this.randomPalette.length > 1) {
                    col2 = this.randomPalette.filter(m => m !== col1)[Math.floor(Math.random() * (this.randomPalette.length - 1))]
                  } else { col2 = '#000000' }
                  gradient.push({
                    tag: 'linearGradient',
                    attrs: { id: 'gradient_' + randomAttr + gradientCounter },
                    content: [
                      { tag: 'stop', attrs: { 'stop-color': col1, offset: 0 } },
                      { tag: 'stop', attrs: { 'stop-color': col2, offset: 1 } }
                    ]
                  })
                  gradientCounter++
                } else {
                  node.attrs.fill = this.randomPalette[Math.floor(Math.random() * this.randomPalette.length)]
                }
              }
            }
          }
          if (this.fullRandom) useLine = this.settingsAggregator.useLine >= Math.random() * 100
          if (useLine) {
            node.attrs.stroke = node.attrs.fill
            node.attrs['stroke-width'] = this.settingsAggregator.lineWidth + 'px'
            node.attrs.fill = 'none'
          }
        }
        // if (node.attrs && node.attrs.class) {
        //   node.attrs.class = ''
        // }
      })
      svgTree[0].content = [{
        tag: 'g',
        content: svgTree[0].content,
        style: 'clip-path: url(#thecrop); -webkit-clip-path: url(#thecrop);',
        attrs: { 'clip-path': 'url(#thecrop)' }
      }]
      svgTree[0].content.push({
        tag: 'defs',
        content: gradient
      })
      svgTree[0].content.push({
        tag: 'clipPath',
        attrs: {
          id: 'thecrop'
          //   clipPathUnits: 'objectBoundingBox'
        },
        content: ({ tag: 'use', attrs: { 'xlink:href': '#bgnd', style: 'overflow:visible;' } })
      })

      const svgHtml = render(svgTree.root)
      return svgHtml
    },
    changeModule (index) {
      if (!this.settingsAggregator.seamless) {
        if (this.settingsAggregator.seamless && this.hotGenerating) {
          this.generateCanvas()
        } else {
          const randomModule = this.randomModules[Math.floor(Math.random() * this.randomModules.length)]
          this.canvasComponents[index].module = randomModule
          this.canvasComponents[index].origin = null
          this.canvasComponents[index].module.factor = this.canvasComponents[index].factor
          this.canvasComponents[index].module.factor = this.canvasComponents[index].factor
          this.canvasComponents[index].module.factor = this.canvasComponents[index].factor
          const obj = parse(randomModule.svgString)
          obj.root.attrs.height = this.canvasComponents[index].size
          obj.root.attrs.width = this.canvasComponents[index].size
          this.canvasComponents[index].string = render(obj.root)
          this.setComponentColor(index)
          this.setComponentPosition(index, 1)
          this.setComponentRotation(index)
        }
      } else (this.generateCanvas())
    },
    checkToBD () {
      if (this.isAnyChangeForDB) {
        this.copyToBase()
      }
    },
    async copyToBase () {
      if (this.theFetchedData) {
        this.saveIsPushed = true
        await this.$store.dispatch('saveMData', { data: localStorage, prid: this.prid })
        this.isAnyChangeForDB = false
        this.saveIsPushed = false
      }
    },
    defaultParams () {
      this.settingsAggregator.transparent = 100
      this.settingsAggregator.gradient = 0
      this.settingsAggregator.density = 100
      this.settingsAggregator.tightness = 100
      this.settingsAggregator.useLine = 0
      this.settingsAggregator.lineWidth = 3
      this.settingsAggregator.opacity = 100
      this.settingsAggregator.opacityMin = 100
      this.settingsAggregator.slide = 0
      this.settingsAggregator.slideY = 0
      this.settingsAggregator.slideMin = 0
      this.settingsAggregator.slideYMin = 0
      this.settingsAggregator.rotate = 0
      this.settingsAggregator.rotateMin = 0
      this.settingsAggregator.scale = 100
      this.settingsAggregator.scaleMin = 100
      if (this.settingsAggregator.seamless) {
        this.mutation.slide = 0
        this.mutation.slideY = 0
        this.mutation.rotate = 0
        this.mutation.scale = 100
        this.mutation.type = 0
        this.mutation.opacity = 100
        this.mutation.slideMin = 0
        this.mutation.slideMinY = 0
        this.mutation.rotateMin = 0
        this.mutation.scaleMin = 100
        this.mutation.opacityMin = 100
        this.mutation.slideFixed = true
        this.mutation.slideFixedY = true
        this.mutation.rotateFixed = true
        this.mutation.scaleFixed = true
        this.mutation.mutationFixed = true
        this.mutation.opacityFixed = true
      }
      this.staticSettingsAggregator.transparentFixed = true
      this.staticSettingsAggregator.gradientFixed = true
      this.staticSettingsAggregator.densityFixed = true
      this.staticSettingsAggregator.densityAll = true
      this.staticSettingsAggregator.tightnessFixed = true
      this.staticSettingsAggregator.useLineFixed = true
      this.staticSettingsAggregator.lineWidthFixed = true
      this.staticSettingsAggregator.opacityFixed = true
      this.staticSettingsAggregator.opacityAll = true
      this.staticSettingsAggregator.slideFixed = true
      this.staticSettingsAggregator.slideYFixed = true
      this.staticSettingsAggregator.rotateFixed = true
      this.staticSettingsAggregator.rotateAll = true
      this.staticSettingsAggregator.scaleFixed = true
      this.staticSettingsAggregator.scaleAll = true
      this.staticSettingsAggregator.limitedFixed = true

      this.defaultRefresh = !this.defaultRefresh
    },
    downloadImg () {
      const today = this.getTheToday()
      if (typeof this.svgCanvas === 'object' && this.svgCanvas !== null
      ) {
        const stringSvg = new XMLSerializer().serializeToString(this.svgCanvas)
        const element = document.createElement('a')
        element.download = `${today}.svg`
        element.href = 'data:application/octet-stream;base64,' + window.btoa(stringSvg)
        element.click()
        element.remove()
      }
    },
    async downloadImgEps () {
      const today = this.getTheToday()
      if (typeof this.svgCanvas === 'object' && this.svgCanvas !== null
      ) {
        const stringSvg = new XMLSerializer().serializeToString(this.svgCanvas)
        const element = document.createElement('a')
        element.download = `${today}.svg`
        element.href = 'data:application/octet-stream;base64,' + window.btoa(stringSvg)
        // var svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(stringSvg)))
        // var Inkscape = require('inkscape')
        // const svgToEpsConverter = new Inkscape(['--export-eps', '--import-svg'])
        element.click()
        element.remove()
      }
    },
    downloadImgAs (format) {
      const today = this.getTheToday()
      if (typeof this.svgCanvas === 'object' && this.svgCanvas !== null
      ) {
        const stringSvg = new XMLSerializer().serializeToString(this.svgCanvas)
        this.svgString2Image(stringSvg, Math.round(this.canvasWidth * this.rastrMultiplier / 100), Math.round(this.canvasHeight * this.rastrMultiplier / 100), format, function (pngData) {
          const element = document.createElement('a')
          element.download = `${today}.${format}`
          element.href = pngData
          element.click()
          element.remove()
        })
      }
    },
    generateCanvasFromSeamless (aggrField, clearField) {
      let current = 0
      const currentModule = this.randomModules[Math.floor(Math.random() * this.randomModules.length)]

      const moduleSvgString = this.setComponentColorSeamless(currentModule.svgString)
      for (let x = 0; x < this.count[0]; x++) {
        for (let y = 0; y < this.count[1]; y++) {
          let skip = false
          if (this.settingsAggregator.densityAll) {
            skip = Math.random() > this.settingsAggregator.density / 100
          }
          if (aggrField.has(`x${x}y${y}`) && !skip) {
            const size = Number(aggrField.get(`x${x}y${y}`))
            const expSize = size * this.moduleSize + (size - 1) * this.grid
            const obj = parse(moduleSvgString)
            obj.root.attrs.height = expSize
            obj.root.attrs.width = expSize
            this.canvasComponents.push({
              module: currentModule,
              string: render(obj.root),
              x: x,
              y: y,
              size: expSize,
              factor: size,
              ratio: 1,
              position: '',
              rotate: `rotate(0, ${expSize / 2}, ${expSize / 2})`,
              reflect: ''
            })
          } else if (clearField.includes(`x${x}y${y}`) || skip) {
          } else {
            this.canvasComponents.push({
              module: currentModule,
              string: moduleSvgString,
              x: x,
              y: y,
              size: this.moduleSize,
              ratio: 1,
              factor: 1,
              position: '',
              rotate: `rotate(0, ${this.moduleSize / 2}, ${this.moduleSize / 2})`,
              reflect: ''
            })
          }
          current++

          if (current >= this.count[0] * this.count[1]) {
            this.generating = false
            let maxX = 1
            let maxY = 1
            this.settingsAggregator.logicAlhoritmString.forEach(cmp => {
              if (cmp.coord[0] > maxX) maxX = cmp.coord[0]
              if (cmp.coord[1] > maxY) maxY = cmp.coord[1]
            })
            this.canvasComponents.forEach((component, index) => {
              this.$nextTick(() => {
                this.mutate(index)
                this.setComponentPosition(index, 1)
                if (this.settingsAggregator.logicAlhoritmString.length === 0) {
                  this.setComponentRotation(index)
                } else {
                  let algoritmIndex = ''
                  this.settingsAggregator.logicAlhoritmString.forEach((logic, li) => {
                    if (Number(logic.coord[0]) === (component.x % maxX) + 1 && Number(logic.coord[1]) === (component.y % maxY) + 1) { algoritmIndex = li }
                  })
                  if (algoritmIndex !== '') {
                    const currentLogic = this.settingsAggregator.logicAlhoritmString[algoritmIndex]
                    if (currentLogic.rotation > 0) {
                      this.setComponentSpecificRotation(index, currentLogic.rotation)
                    } else {
                      this.setComponentRotation(index)
                    }
                    if (currentLogic.reflection > 0) this.setComponentSpecificReflection(index, currentLogic.reflection)
                    if (currentLogic.color > 0) this.setComponentSpecificColor(index, currentLogic.color - 1)
                  }
                }
              })
            })
          }
        }
      }
    },
    generateCanvasRandom (aggrField, clearField) {
      let current = 0
      for (let x = 0; x < this.count[0]; x++) {
        for (let y = 0; y < this.count[1]; y++) {
          let skip = false
          if (this.settingsAggregator.densityAll) {
            skip = Math.random() > this.settingsAggregator.density / 100
          }
          const currentModule = this.randomModules[Math.floor(Math.random() * this.randomModules.length)]
          if (aggrField.has(`x${x}y${y}`) && !skip) {
            const size = Number(aggrField.get(`x${x}y${y}`))
            const expSize = size * this.moduleSize + (size - 1) * this.grid
            const obj = parse(currentModule.svgString)
            obj.root.attrs.height = expSize
            obj.root.attrs.width = expSize
            this.canvasComponents.push({
              module: currentModule,
              string: render(obj.root),
              x: x,
              y: y,
              size: expSize,
              factor: size,
              ratio: 1,
              position: '',
              rotate: '',
              reflect: ''
            })
          } else if (clearField.includes(`x${x}y${y}`) || skip) {
          } else {
            this.canvasComponents.push({
              module: currentModule,
              string: currentModule.svgString,
              x: x,
              y: y,
              size: this.moduleSize,
              ratio: 1,
              factor: 1,
              position: '',
              rotate: '',
              reflect: ''
            })
          }
          current++

          if (current >= this.count[0] * this.count[1]) {
            this.generating = false

            this.canvasComponents.forEach((component, index) => {
              this.$nextTick(() => {
                this.setComponentPosition(index, 1)
                this.setComponentRotation(index)
                this.setComponentColor(index)
              })
            })
          }
        }
      }
    },
    generatedFieldsForCanvas (aggregatedField) {
      let clearField = []
      let deprecatedFields = []
      let aggrField = new Map()
      const fieldAggregator = []
      // if (aggregatedField.indexOf('(') !== -1) {
      //   const newGrid = aggregatedField.match(/\(\d+:\d+\)/)[0].replace('(', '').replace(')', '').split(':')
      //   if (newGrid.length === 2) {
      //     this.count = newGrid
      //   }
      // }
      // if (aggregatedField.indexOf('@') !== -1) {
      //  fieldAggregator = this.aggregatedField.replace(/\(\d+:\d+\)/, '').split('@')
      // } else
      // if (aggregatedField.indexOf('%') !== -1 && this.readyForGenerate === true) {
      if (this.aggregatedField.length > 0) {
        // let addiction = ''
        // if (aggregatedField.indexOf('*') !== -1) {
        //   const multiplier = aggregatedField.match(/\d+\*\d+/g)
        //   multiplier.forEach(mult => {
        //     for (let i = 0; i < mult.split('*')[1]; i++) {
        //       addiction += '%' + mult.split('*')[0]
        //     }
        //   })
        // }
        // const cleared = aggregatedField.replace(/%\d+\*\d+/g, '').replace(/\(\d+:\d+\)/, '') + addiction
        // fieldAggregator = cleared.split('%').map(function (x) {
        //   return parseInt(x)
        // }).sort((a, b) => b - a)

        this.aggregatedField.forEach(field => {
          for (let i = 0; i < field.count; i++) {
            fieldAggregator.push(field.size)
          }
        })

        fieldAggregator.forEach(sizes => {
          const size = Number(sizes)
          if (size < 2) return
          let watcher = 0
          let localAggregatedFieldIsCorrupt = true

          while (localAggregatedFieldIsCorrupt && watcher < 1000) {
            const predeprecatedFields = []
            localAggregatedFieldIsCorrupt = false
            const coord = [Math.floor(Math.random() * (this.count[1] - size + 1)), Math.floor(Math.random() * (this.count[0] - size + 1))]
            if (deprecatedFields.includes(coord)) {
              continue
            }
            const preClearField = []
            const preAggrField = new Map()
            for (let x = 0; x < this.count[0]; x++) {
              for (let y = 0; y < this.count[1]; y++) {
                if (((coord[0] + size - 1) >= y && y >= coord[0]) && ((coord[1] + size - 1) >= x && x >= coord[1]) && !(y === coord[0] && x === coord[1])) {
                  if (clearField.includes(`x${x}y${y}`)) {
                    localAggregatedFieldIsCorrupt = true
                  }
                  preClearField.push(`x${x}y${y}`)
                  predeprecatedFields.push([x, y])
                } else if (y === coord[0] && x === coord[1]) {
                  if (clearField.includes(`x${x}y${y}`)) {
                    localAggregatedFieldIsCorrupt = true
                  }
                  preAggrField.set(`x${x}y${y}`, size)
                  preClearField.push(`x${x}y${y}`)
                  predeprecatedFields.push([x, y])
                }
              }
            }
            watcher++
            if (!localAggregatedFieldIsCorrupt && preClearField.length > 0) {
              clearField = clearField.concat(preClearField)
              deprecatedFields = deprecatedFields.concat(predeprecatedFields)
              aggrField = new Map([...aggrField].concat([...preAggrField]))
              // this.theCanvasFormula.resize.push([coord, size])
            }
          }
        })
      }
      if (fieldAggregator.length > 0 && aggrField.size === 0) {
        fieldAggregator.forEach(field => {
          const f = field.split('|')
          const coord = [Number(f[0]) - 1, Number(f[1]) - 1]
          const size = Number(f[2])
          for (let x = 0; x < this.count[0]; x++) {
            for (let y = 0; y < this.count[1]; y++) {
              if (y === coord[0] && x === coord[1]) {
                if (clearField.includes(`x${x}y${y}`)) {
                  this.aggregatedFieldIsCorrupt = true
                  return false
                }
                aggrField.set(`x${x}y${y}`, size)
                clearField.push(`x${x}y${y}`)
              } else if (((coord[0] + size - 1) >= y && y >= coord[0]) && ((coord[1] + size - 1) >= x && x >= coord[1])) {
                if (clearField.includes(`x${x}y${y}`)) {
                  this.aggregatedFieldIsCorrupt = true
                  return false
                }
                clearField.push(`x${x}y${y}`)
              }
            }
          }
        })
      }
      if (this.aggregatedFieldIsCorrupt) {
        clearField = []
        aggrField = new Map()
        this.aggregatedFieldIsCorrupt = false
      }
      return [clearField, aggrField]
    },
    getLastCanvas () {
      if (this.canvasArchive[2]) {
        this.canvasComponents = this.canvasArchive[2]
        this.randomModules = this.modulesArchive[2]
        this.randomPalette = this.palettesArchive[2]
        this.background = this.canvasScaleArchive[2][0]
        this.count = this.canvasScaleArchive[2][1]
        this.newCount = this.canvasScaleArchive[2][1]
        this.grid = this.canvasScaleArchive[2][2]
        this.spaces = this.canvasScaleArchive[2][3]
        this.settedPalette = this.selectedPalettesArchive[2]
      }
    },
    saveLastCanvas () {
      this.selectedPalettesArchive[2] = this.selectedPalettesArchive[1]
      this.selectedPalettesArchive[1] = this.settedPalette
      this.modulesArchive[2] = this.modulesArchive[1]
      this.palettesArchive[2] = this.palettesArchive[1]
      this.modulesArchive[1] = this.randomModules
      this.palettesArchive[1] = this.randomPalette
      this.canvasScaleArchive[2] = this.canvasScaleArchive[1]
      this.canvasScaleArchive[1] = [this.background, this.count, this.grid, this.spaces]
      this.canvasArchive[2] = this.canvasArchive[1]
      this.canvasArchive[1] = this.canvasComponents
    },
    getNextCanvas () {
      if (this.canvasArchive[1]) {
        this.canvasComponents = this.canvasArchive[1]
        this.randomModules = this.modulesArchive[1]
        this.randomPalette = this.palettesArchive[1]
        this.background = this.canvasScaleArchive[1][0]
        this.count = this.canvasScaleArchive[1][1]
        this.newCount = this.canvasScaleArchive[1][1]
        this.grid = this.canvasScaleArchive[1][2]
        this.spaces = this.canvasScaleArchive[1][3]
        this.settedPalette = this.selectedPalettesArchive[1]
      }
    },
    getTheToday () {
      let today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')
      const hours = String(today.getHours()).padStart(2, '0')
      const minutes = String(today.getMinutes()).padStart(2, '0')
      const seconds = String(today.getSeconds()).padStart(2, '0')
      today = year + '.' + month + '.' + day + '_' + hours + '.' + minutes + '.' + seconds
      return today
    },
    async getDataFromLocalStorage () {
      await this.$store.dispatch('getTheModulesLibrary')
      if (localStorage.modules) {
        Promise.resolve(this.getDataFromLocalModules(JSON.parse(localStorage.modules))).then(value => {
          this.modules = value
        })
      }
      if (localStorage.limited) {
        this.limited = JSON.parse(localStorage.limited)
      }
      if (localStorage.settingsAggregator) {
        this.settingsAggregator = JSON.parse(localStorage.settingsAggregator)
      }
      if (localStorage.staticSettingsAggregator) {
        this.staticSettingsAggregator = JSON.parse(localStorage.staticSettingsAggregator)
      }
      if (localStorage.mutation) {
        this.mutation = JSON.parse(localStorage.mutation)
      }
      if (localStorage.extensionType) {
        this.extensionType = JSON.parse(localStorage.extensionType)
      }
      if (localStorage.palettes) {
        this.palettes = JSON.parse(localStorage.palettes)
      }
      if (localStorage.fullRandom) {
        this.fullRandom = JSON.parse(localStorage.fullRandom)
      }
      if (localStorage.rotate) {
        this.rotate = JSON.parse(localStorage.rotate)
      }
      if (localStorage.hotGenerating) {
        this.hotGenerating = JSON.parse(localStorage.hotGenerating)
      }
      if (localStorage.count) {
        this.count = JSON.parse(localStorage.count)
        this.newCount = JSON.parse(localStorage.count)
      }
      if (localStorage.grid) {
        this.grid = JSON.parse(localStorage.grid)
      }
      if (localStorage.spaces) {
        this.spaces = JSON.parse(localStorage.spaces)
      }
      if (localStorage.background) {
        this.background = JSON.parse(localStorage.background)
      }
      if (localStorage.rastrMultiplier) {
        this.rastrMultiplier = JSON.parse(localStorage.rastrMultiplier)
      }
      this.theFetchedData = true
      this.isAnyChangeForDB = false
    },
    manualModuleChange () {
      this.limited = 0
      this.staticSettingsAggregator.limitedFixed = true
    },
    moduleIsHover (index) {
      this.moduleHover = index
    },
    moduleIsUnhover (index) {
      if (this.moduleHover === index) {
        this.moduleHover = null
      }
    },
    resizeTheCanvasElement (index) {
      const size = this.canvasComponents[index].factor
      if (size > 1) {
        const expSize = size * this.moduleSize + (size - 1) * this.grid
        const obj = parse(this.canvasComponents[index].string)
        obj.root.attrs.height = expSize
        obj.root.attrs.width = expSize
        this.canvasComponents[index].size = expSize
        this.canvasComponents[index].string = render(obj.root)
        let rot = 0
        if (this.canvasComponents[index].rotate.length > 0) { rot = Number(this.canvasComponents[index].rotate.match(/rotate\(([\d]{1,3})/)[1]) }
        this.canvasComponents[index].rotate = `rotate(${rot}, ${expSize / 2}, ${expSize / 2})`
        let mirror = 1
        if (this.canvasComponents[index].reflect.length > 0) {
          const match = this.canvasComponents[index].reflect.match(/scale\(([-]{0,1}\d), ([-]{0,1}\d)/)
          if (match[1] > match[2]) {
            mirror = 2
          } else {
            mirror = 3
          }
        }
        let mirrorTransform = ''
        if (mirror === 3) {
          mirrorTransform = `translate(${expSize}, 0) scale(-1, 1)`
        } else if (mirror === 2) {
          mirrorTransform = `translate(0, ${expSize}) scale(1, -1)`
        }
        this.canvasComponents[index].reflect = `${mirrorTransform}`
        this.setComponentPosition(index, 1)
      }
    },
    selectRandomModules () {
      if (this.limited > 0) {
        this.holdGenerating = true
        const amount = this.modules.length
        const keys = Object.keys(this.modules)
        const arr = []
        this.modules.forEach(module => {
          this.holdGenerating = true
          module.selected = false
        })
        let overlaped = 0
        while (arr.length < this.limited && overlaped < 1000) {
          const r = Math.floor(Math.random() * amount) + 1
          if (arr.indexOf(r) === -1) arr.push(r)
          overlaped++
        }
        for (let i = 0; i < arr.length; i++) {
          this.holdGenerating = true
          this.modules[keys[arr[i] - 1]].selected = true
        }
        this.holdGenerating = false
      } else {
        return false
      }
    },
    selectSingleModule (index) {
      const theIndex = (this.canvasComponents[index].module.indexer)
      this.modules.forEach(module => {
        if (module.indexer !== theIndex) { module.selected = false } else module.selected = true
      })
    },
    setComponentSpecificColor (index, palid) {
      if (palid < this.randomPalette.length) {
        const fill = this.randomPalette[palid]
        const component = this.canvasComponents[index]
        const svgTree = parse(component.string)
        svgTree.each((node) => {
          if (['rect', 'circle', 'ellipse', 'line', 'polyline', 'polygon', 'path'].includes(node.tag)) {
            if (node.attrs.fill !== 'none') {
              node.attrs.fill = fill
            }
          }
        })
        component.string = render(svgTree.root)
      } else this.setComponentColor(index)
    },
    setComponentSpecificRotation (index, value) {
      if (value > 359) value = Number(value) - 360
      this.canvasComponents[index].rotate = `rotate(${value}, ${this.canvasComponents[index].size / 2}, ${this.canvasComponents[index].size / 2})`
    },
    setComponentSpecificReflection (index, mirror) {
      mirror = Number(mirror)
      let mirrorTransform = ''
      if (mirror === 1) {
        mirrorTransform = `translate(${this.canvasComponents[index].size}, 0) scale(-1, 1)`
      } else if (mirror === 2) {
        mirrorTransform = `translate(0, ${this.canvasComponents[index].size}) scale(1, -1)`
      }
      this.canvasComponents[index].reflect = `${mirrorTransform}`
    },
    setComponentPosition (index, split) {
      const component = this.canvasComponents[index]
      const positionX = component.x * this.moduleSize / split + this.gridSize * component.x + this.realSpaces.left
      const positionY = component.y * this.moduleSize + this.gridSize * component.y + this.realSpaces.top
      component.positionX = positionX
      component.positionY = positionY
      component.position = `translate(${component.positionX}, ${component.positionY})`
    },
    setComponentRotation (index) {
      if (this.rotate) {
        const component = this.canvasComponents[index]
        const rotate = Math.floor(Math.random() * 4) * 90
        const mirror = Math.floor(Math.random() * 3)
        let mirrorTransform = ''

        if (mirror === 1) {
          mirrorTransform = `translate(${component.size}, 0) scale(-1, 1)`
        } else if (mirror === 2) {
          mirrorTransform = `translate(0, ${component.size}) scale(1, -1)`
        }

        this.canvasComponents[index].reflect = `${mirrorTransform}`
        this.canvasComponents[index].rotate = `rotate(${rotate}, ${component.size / 2}, ${component.size / 2})`
      }
    },
    setRandomModules () {
      let modules = this.selectedModules.length ? this.selectedModules : []
      if (!modules.length && this.modules.length) {
        const randomLength = Math.floor(Math.random() * this.modules.length)
        modules = JSON.parse(JSON.stringify(this.modules))

        for (let i = 0; i < randomLength; i++) {
          modules.splice(Math.floor(Math.random() * modules.length), 1)
        }
      }
      this.randomModules = modules
    },
    setRandomPalette () {
      let palette = this.selectedPalette.length ? this.selectedPalette[0].colors : []
      if (!palette.length && this.palettes.length) {
        this.paletesAutoset = Math.floor(Math.random() * this.palettes.length)
        palette = this.palettes[this.paletesAutoset].colors
      }
      this.settedPalette = []
      palette.forEach(p => {
        this.settedPalette.push({ color: p, selected: true })
      })
    },
    svgString2Image (svgString, width, height, format, callback) {
      format = format || 'png'
      var svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)))
      var canvas = document.createElement('canvas')
      var context = canvas.getContext('2d')
      canvas.width = width
      canvas.height = height
      var image = new Image()
      const noiseMut = this.settingsAggregator.noiseMutation
      const noiseOpa = this.settingsAggregator.noiseOpacity
      image.onload = function () { // async (happens later)
        context.clearRect(0, 0, width, height)
        context.drawImage(image, 0, 0, width, height)
        const imageData = context.getImageData(0, 0, width, height)
        function applyNoise (Target, type) {
          Target = Target / 255
          const Blend = Math.random()
          switch (type) {
            case 'Multiply': {
              return Target * Blend
            }
            case 'Lighten': {
              return Math.max(Target, Blend)
            }
            case 'Darken': {
              return Math.min(Target, Blend)
            }
            case 'Color Burn': {
              return 1 - (1 - Target) / Blend
            }
            case 'Linear Burn': {
              return Target + Blend - 1
            }
            case 'Difference': {
              return Math.abs(Target - Blend)
            }
            case 'Exclusion': {
              return 0.5 - 2 * (Target - 0.5) * (Blend - 0.5)
            }
            case 'Screen': {
              return 1 - (1 - Target) * (1 - Blend)
            }
            case 'Color Dodge': {
              return Target / (1 - Blend)
            }
            case 'Linear Dodge': {
              return Target + Blend
            }
            case 'Overlay': {
              return (Target > 0.5) * (1 - (1 - 2 * (Target - 0.5)) * (1 - Blend)) + (Target <= 0.5) * ((2 * Target) * Blend)
            }
            case 'Soft Light': {
              return (Blend > 0.5) * (1 - (1 - Target) * (1 - (Blend - 0.5))) + (Blend <= 0.5) * (Target * (Blend + 0.5))
            }
            case 'Hard Light': {
              return (Blend > 0.5) * (1 - (1 - Target) * (1 - 2 * (Blend - 0.5))) + (Blend <= 0.5) * (Target * (2 * Blend))
            }
            default:
              return false
          }
        }
        if (noiseMut !== '' && noiseMut !== undefined) {
          const pixels = imageData.data
          const alpha = noiseOpa / 100
          const alpha1 = 1 - alpha
          let r, g, b, origR, origG, origB
          for (let i = 0, il = pixels.length; i < il; i += 4) {
            origR = pixels[i]
            origG = pixels[i + 1]
            origB = pixels[i + 2]

            r = applyNoise(origR, noiseMut) * 255
            g = applyNoise(origG, noiseMut) * 255
            b = applyNoise(origB, noiseMut) * 255
            pixels[i] = r * alpha + origR * alpha1
            pixels[i + 1] = g * alpha + origG * alpha1
            pixels[i + 2] = b * alpha + origB * alpha1
          }
        }

        context.putImageData(imageData, 0, 0)
        var pngData = canvas.toDataURL('image/' + format)
        callback(pngData)
      }
      image.src = svgData
    },
    useThisAutosettedPalette () {
      this.palettes[this.paletesAutoset].selected = true
    },
    unuseThisAutosettedPalette () {
      this.palettes.forEach(palette => { palette.selected = false })
    },
    populateDividableModule () {
      // TODO: split modules inside the border
    },
    downloadSettedImg () {
      if (this.extensionType.includes('svg')) this.downloadImg()
      if (this.extensionType.includes('eps')) this.downloadImgEps()
      if (this.extensionType.includes('png')) this.downloadImgAs('png')
      if (this.extensionType.includes('jpeg')) this.downloadImgAs('jpeg')
    },
    inputClearValue ($event, accepted) {
      const defaultKeys = [8, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 190, 36, 37, 38, 39, 40, 173, 189, 92, 124, 220, 64, 94, 40, 41, 42]
      const keyCode = $event.keyCode
      if (!defaultKeys.includes(keyCode) && !accepted.includes(keyCode)) {
        $event.preventDefault()
      }
    },
    cycleControl (event, max, min) {
      if (event.target.value >= max) event.target.value = Number(event.target.value) % max
      if (event.target.value < min) event.target.value = Number(event.target.value) + max
      return event.target.value
    },
    cycleIncrement (value, max, increment) {
      if (value < max) return value + increment
      else return 0
    },
    pushNewLogic () {
      let maxX = 0
      let maxY = 1
      this.settingsAggregator.logicAlhoritmString.forEach(cmp => {
        if (cmp.coord[0] > maxX) maxX = Number(cmp.coord[0])
        if (cmp.coord[1] > maxY) maxY = Number(cmp.coord[1])
      })
      this.settingsAggregator.logicAlhoritmString.push({ coord: [maxX + 1, maxY], rotation: 0, reflection: 0, color: 0 })
    },
    showModal (name) {
      this.$modal.show(name)
      window.removeEventListener('keydown', this.keyListener, false)
    },
    hideModal (name) {
      this.$modal.hide(name)
      window.addEventListener('keydown', this.keyListener, false)
    },
    applyFieldSettins (data) {
      this.count = data.count
      this.grid = data.grid
      this.spaces = data.spaces
      this.aggregatedField = data.aggregatedField
      this.newCount = data.newCount
      this.generateCanvas()
    },
    saveFieldSettingsToDB () {
      const data = {
        count: this.count,
        grid: this.grid,
        spaces: this.spaces,
        aggregatedField: this.aggregatedField,
        newCount: this.newCount
      }
      this.saveTheDataToDB(data, 'field', this.dbData.fieldName)
    },
    async saveTheDataToDB (data, type, name) {
      await this.$store.dispatch('saveDataToDatabase', { data, type, name })
    },
    async getLogicDataFromDB (type) {
      await this.$store.dispatch('getDataListFromDatabase', type)
      this.dbData[type + 'Data'] = JSON.parse(this.$store.getters[type])
    },
    async deleteListFromDB (type, id, index) {
      await this.$store.dispatch('deleteListElementFromDatabase', { type, id })
      this.dbData[type + 'Data'].splice(index, 1)
    },
    async getDataFromLocalModules (modules) {
      let db
      const dbReq = await indexedDB.open('db', 1)
      dbReq.onsuccess = (event) => {
        db = event.target.result
        const tx = db.transaction(['modules'], 'readonly')
        const store = tx.objectStore('modules')
        modules.forEach((module, index) => {
          const req = store.get(module.indexer)
          req.onsuccess = (event) => {
            const data = event.target.result
            if (data) {
              module.dataUri = data.dataUri
              module.svgString = data.svgString
            } else {
              console.log(module.indexer + ' not found')
            }
            if (index === modules.length - 1) {
              this.modulesRefresh = true
              this.doNotGenerate = false
              if (this.firstGenetration) {
                this.firstGenetration = false
                this.generateCanvas()
              } else if (this.hotGenerating) {
                this.generateCanvas()
              }
            }
          }
        })
      }
      return modules
    },
    async getAllCollection () {
      await this.$store.dispatch('getAllCollectionData')
      this.dbCollections = this.$store.getters.data
    },
    addToCollection (value) {
      if (!this.collections.replace) {
        const nowm = []
        this.modules.forEach(mod => {
          nowm.push(mod.indexer)
        })
        const nm = []
        value.forEach(key => {
          if (!nowm.includes(key)) {
            nm.push({ indexer: key, selected: false })
          }
        })
        if (!localStorage.modules) localStorage.modules = '[]'
        localStorage.modules = JSON.stringify(JSON.parse(localStorage.modules).concat(nm))
      } else {
        const nm = []
        value.forEach(key => {
          nm.push({ indexer: key, selected: false })
        })
        localStorage.modules = JSON.stringify(nm)
      }
      this.modulesRefresh = false
      if (!localStorage.modules) localStorage.modules = '[]'
      Promise.resolve(this.getDataFromLocalModules(JSON.parse(localStorage.modules))).then(value => {
        this.modules = value
      })
      this.hideModal('mCollection')
    },
    addCollectionFromList (list) {
      let newm = []
      list.forEach(i => {
        newm = newm.concat(this.dbCollections[i].keys)
      })
      const clearm = newm.filter((value, index, self) => self.indexOf(value) === index)
      this.addToCollection(clearm)
    },
    togleSettedPalette (key) {
      this.settedPalette[key].selected = !this.settedPalette[key].selected
    },
    tabTogglerWasClicked (tab) {
      if (this.currentKey === 'Ctrl') {
        this.staticSettingsAggregator.activeTabs = [tab]
      } else {
        this.staticSettingsAggregator.activeTabs.includes(tab) ? this.staticSettingsAggregator.activeTabs.splice(this.staticSettingsAggregator.activeTabs.indexOf(tab), 1) : this.staticSettingsAggregator.activeTabs.push(tab)
      }
    }

  },
  beforeDestroy () {
    clearInterval(this.toDBinterval)
    window.removeEventListener('keydown', this.keyListener, false)
  }
}
</script>
<style src="@vueform/slider/themes/default.css"></style>
