<template>
    <modal
        name="modal"
        :adaptive="true"
        width="500"
        height="auto"
        @closed="closed"
    >
        <div class="v--modal-inner">
            <p class="h5 v--modal-title">Add Palette</p>

            <a
                href="#"
                class="v--modal-close"
                @click.prevent="hide"
            >Cancel</a>

            <div v-if="newPalette" class="v--modal-palette">
                <ul class="v--modal-palette__list">
                    <li
                        v-for="(color, index) in newPalette"
                        :key="`color${index}`"
                        class="v--modal-palette__list-item"
                    >
                        <div class="v--modal-palette__item">
                            <VuePickr v-model="color.value" @clear="clear(index)" />
                        </div>
                    </li>

                    <li v-if="newPalette.length < 256" class="v--modal-palette__list-item">
                        <div class="c-section-add">
                            <a
                                href=""
                                class="c-section-add__link"
                                @click.prevent="newPalette.push({value: '#000000'})"
                            >
                                <span class="c-section-add__link-inner">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="12.5" y1="2.18557e-08" x2="12.5" y2="24" stroke="#444444"/>
<line x1="24" y1="12.5" x2="-4.37114e-08" y2="12.5" stroke="#444444"/>
</svg>
                                </span>
                            </a>
                        </div>
                    </li>
                </ul>

                <div v-if="newPalette.length >= 1" class="v--modal-btns">
                    <div class="v--modal-btns__item">
                        <a
                            href="#"
                            class="btn btn-dark"
                            @click.prevent="save"
                        >SAVE</a>
                    </div>
                    <div class="v--modal-btns__item">
                        <a
                            href=""
                            class="v--modal-palette__delete"
                            @click.prevent="remove"
                        >Remove palette</a>
                    </div>
                </div>

                <span v-else>Add a minimum 1 color</span>
            </div>
        </div>
    </modal>
</template>

<script>
import VuePickr from './VuePickr.vue'

export default {
  components: {
    VuePickr
  },
  props: {
    palette: {
      type: [Array],
      required: false,
      default: null
    }
  },
  data () {
    return {
      newPalette: []
    }
  },
  watch: {
    palette (value) {
      this.newPalette = []

      if (value) {
        value.forEach(value => {
          this.newPalette.push({ value })
        })
        this.show()
      }
    }
  },
  mounted () {
    this.newPalette = this.palette
  },
  methods: {
    show () {
      this.$modal.show('modal')
    },
    hide () {
      this.$modal.hide('modal')
    },
    save () {
      const palette = this.newPalette.map(element => element.value)
      this.$emit('save', palette)
      this.hide()
    },
    clear (index) {
      this.newPalette.splice(index, 1)
    },
    remove () {
      this.$emit('remove')

      this.hide()
    },
    closed () {
      this.$emit('close')
    }
  }
}
</script>
