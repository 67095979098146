import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  actions: {
    async login ({ dispatch, commit }, { email, password }) {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    getUid () {
      const user = firebase.auth().currentUser
      return user ? user.uid : null
    },
    async logout ({ dispatch, commit }) {
      await firebase.auth().signOut()
      commit('clearInfo')
      localStorage.clear()
    }
  }
}
