<template>
  <nav class="navbar orange lighten-1">
    <div class="nav-wrapper">
      <div class="navbar-left">
        <span class="black-text">{{date | date('datetime')}}</span>
      </div>
      <div class="navbar-left">
        <span class="black-text  fsz40">{{ pname }}</span>
      </div>

      <ul class="right hide-on-small-and-down">
        <li>
          <a
              class="dropdown-trigger black-text"
              href="#"
              data-target="dropdown"
              ref="dropdown"
          >
            <span class="login-text fsz20">{{ name }}</span>
            <i class="material-icons right">arrow_drop_down</i>
          </a>

          <ul id='dropdown' class='dropdown-content'>
            <li v-for="(link,index) in links "
            :key="`link${index}`"
            >
              <router-link :to="link.url" class="black-text">
                <i class="material-icons">account_circle</i>{{link.name}}
              </router-link>
            </li>
            <li class="divider" tabindex="-1"></li>
            <li>
              <a href="#" class="black-text" @click.prevent="logout">
                <i class="material-icons">assignment_return</i>Logout
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data: () => ({
    date: new Date(),
    interval: null,
    dropdown: null,
    links: [
      { name: 'My Project', url: '/' },
      { name: 'Modules', url: '/modules' },
      { name: 'Palettes', url: '/privpalettes' }
      // { name: 'Preset settings', url: '/preset' }
    ]
  }),
  methods: {
    async logout () {
      await this.$store.dispatch('logout')
      this.$router.push('/login?message=logout')
    },
    toLogin () {
      this.$router.push('/login')
    }
  },
  computed: {
    name () {
      return this.$store.getters.info.name
    },
    pname () {
      return this.$route.query.n
    }
  },
  async mounted () {
    const uid = await this.$store.dispatch('getUid')
    if (!uid) this.toLogin()
    this.$store.dispatch('fetchInfo')
    this.interval = setInterval(() => {
      this.date = new Date()
    }, 1000)
    this.dropdown = window.M.Dropdown.init(this.$refs.dropdown, {
      constrainWidth: false
    })
  },
  beforeDestroy () {
    clearInterval(this.interval)
    if (this.dropdown && this.dropdown.destroy) {
      this.dropdown.destroy()
    }
  }
}
</script>
