<template>
    <div class="c-spaces" :class="{ 'is-combined': combined }">
        <ul class="c-section-list" @click.prevent="changeCombined">
            <li
                v-for="(space, index) in [
                    'left',
                    'top',
                    'bottom',
                    'right',
                ]"
                :key="'spaces' + index"
                class="c-section-list__item"
            >
                <div class="c-spaces-item">
                    <input
                        type="number"
                        :value="value[space]"
                        class="form-control browser-default"
                        :name="`${space}Space`"
                        :min="min"
                        @keypress="isNumber($event)"
                        @change="change(space, $event.target.value)"
                        @update="change(space, $event.target.value)"
                    >
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: (Object),
      required: true
    },
    currentKey: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  data () {
    return {
      min: 0,
      max: 4096,
      combined: true
    }
  },
  methods: {
    isNumber (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    changeCombined () {
      if (this.currentKey === 'Shift') {
        this.combined = !this.combined
      }
    },
    change (name, value) {
      const space = JSON.parse(JSON.stringify(this.value))
      value = Number(value)

      if (isNaN(value)) {
        value = this.min
      }

      if (value <= this.min) {
        value = this.min
      }

      if (value >= this.max) {
        value = this.max
      }

      space[name] = value

      if (this.combined) {
        Object.keys(space).forEach(key => {
          space[key] = value
        })
      }

      this.$emit('input', space)
    }
  }
}
</script>
