<template>
    <div class="c-section-form">
        <div class="c-background-item">
            <VuePickr
                v-model="color"
                :swatches="palette"
                @clear="clear"
            />
        </div>
    </div>
</template>

<script>
import VuePickr from '../VuePickr.vue'

export default {
  components: {
    VuePickr
  },
  props: {
    value: {
      type: [String],
      required: false,
      default: null
    },
    palette: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      color: null
    }
  },
  watch: {
    color (color) {
      this.$emit('input', color)
    }
  },
  mounted () {
    this.color = this.value
  },
  methods: {
    clear () {
      this.$emit('input', null)
    }
  }
}
</script>
