<template>
    <div class="c-section c-rotate">
        <div class="c-section-heading">
            <p class="c-section-title">ROTATE</p>
        </div>

        <div class="c-section-form">
            <div class="custom-control custom-checkbox">
                <input
                    id="rotate"
                    v-model="rotate"
                    class="custom-control-input"
                    type="checkbox"
                    :checked="value"
                >
                <label for="rotate" class="custom-control-label" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      rotate: false
    }
  },
  watch: {
    rotate (value) {
      this.$emit('input', value)
    }
  },
  mounted () {
    this.rotate = this.value
  }
}
</script>
